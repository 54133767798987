import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { PersonViewModel } from '../../view-models/person.view-model';
import { GeoDsCoreDataService, GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, QueryColumn, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { AppService } from '../../../services/app.service';
import { ToastType } from '@wissenswerft/ww-library';
import { Observable, Subscription, of } from 'rxjs';
import { Person, PhoneNumber } from '@geods/base';
import { PERSISTDATA, UploadService } from '../../../services/upload.service';
import { EcoUploaderComponent } from '../../shared/eco-uploader/eco-uploader.component';
import { map, switchMap } from 'rxjs/operators';
import { MaterialPickList } from '../../view-models/material.view-model';
import CustomStore from 'devextreme/data/custom_store';
import { DropBoxService } from '../../shared/eco-drop-box/eco-drop-box.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'ecoBase-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss']
})
export class CreateEmployeeComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: DxFormComponent;
  @ViewChild('pictureUploader') pictureUploader: EcoUploaderComponent;
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  @Output() createEmploye: EventEmitter<{ isUpdated: boolean, Id: string }> = new EventEmitter();

  public employee: PersonViewModel = new PersonViewModel(new Person(null));
  public object = ObjectKeys.PERSON;
  public update = false;
  private subscriptions: Subscription[] = [];
  public salutations: DataSource;
  public parentId = '';
  public functionStore: CustomStore;
  public isPhoneReady = false;
  public phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  });
  public createButtonOptions = {
    text: this.dataService.res('Eco-Create'),
    useSubmitBehavior: true
  };

  public CancelButtonOptions = {
    text: this.dataService.res('Eco-Cancel'),
    onClick: () => this.onClosePopup()
  };
  constructor(
    public dataService: DataService,
    private appService: AppService,
    private persistenceService: GeoDsPersistenceService,
    private coreDataService: GeoDsCoreDataService,
    private uploadService: UploadService,
    private dropBoxService: DropBoxService
  ) { }

  ngOnInit(): void {
    //TODO change loadListDataSource with dataService readPickList
    this.dataService.loadListDataSource(ObjectKeys.PERSON, "Salutation").subscribe((salutationData) => {
      this.salutations = new DataSource({
        store: {
          type: 'array',
          data: salutationData.Data,
          key: "Value"
        },
        sort: [
          { selector: "Description", desc: false }
        ]
      })
    });
    this.getCompanyName();
    this.subscriptions.push(
      this.dataService.getPickListItemsByPickListName(MaterialPickList.FUNCTION)
        .subscribe((functions) => {
          this.functionStore = this.dropBoxService.returnCustomStore(MaterialPickList.FUNCTION, functions);
        })
    )
  }
  public changeFunctionName(e): void {
    this.employee.function = e;
  }

  // TODO opath to be replaced with "Id=$CurrentLicenseAddressId" when fixed 
  public getCompanyName() {
    let columns: QueryColumn[] = [
      this.coreDataService.createQueryColumn('Id', 'Id')
    ]
    let opath = "Name='ECOROLL'"
    this.subscriptions.push(
      this.dataService.readObjects(ObjectKeys.ADDRESS, columns, opath)
        .subscribe((companyData) => {
          this.parentId = companyData[0]?.Id;
        }, error => {
          console.error(error);
        })
    );
  }

  public persistEmployee(event): void {
    event.preventDefault();
    this.subscriptions.push(
      this.persistEmployeeReq().pipe(
        switchMap((persistedEmployee) => {
          return this.persistPhoneNumber(this.employee.phoneNumber, persistedEmployee.Id)
            .pipe(map(phone => persistedEmployee))
        }),
      )
        .subscribe((data) => {
          const employeePicturePersistData: PERSISTDATA = {
            parentId: data.Id,
            object: ObjectKeys.PERSON,
            designation: "profilePicture",
            docId: this.employee.profilePicture ? this.employee.profilePictureId : undefined

          }
          this.uploadService.onLaunchPersist(employeePicturePersistData);
          this.pictureUploader.reset();
          this.createEmploye.emit({ isUpdated: this.update, Id: data.Id })
        }, (error) => {
          this.appService.callNotification({
            message: this.dataService.res('Eco-Error'),
            type: ToastType.ERROR
          });
        }, () => {
          this.appService.callNotification({
            message: this.dataService.res('Eco-Success'),
            type: ToastType.SUCCESS
          });
          this.onClosePopup();
        })
    )
  }
  public persistEmployeeReq(): Observable<any> {
    const employeeQuery = this.employee.preparePersonReq();
    employeeQuery.ParentId = this.parentId;
    const employeePersistQuery: TargetObjectData = new TargetObjectData();
    employeePersistQuery.ObjectKey = new ObjectKey();
    if (this.update) {
      employeePersistQuery.Mode = PersistMode.Update;
      employeePersistQuery.ObjectKey.Id = this.employee.id;
    } else {
      employeePersistQuery.Mode = PersistMode.Insert;
    }
    employeePersistQuery.ObjectKey.ObjectType = ObjectKeys.PERSON;
    const employeeColumns: TargetColumnValue[] = this.dataService.geoDsPersistBody(employeeQuery);
    employeePersistQuery.TargetColumns = employeeColumns;
    const query: PersistObjectModel = new PersistObjectModel();
    query.Object = employeePersistQuery;
    return this.persistenceService.executePersistObjectQuery(query);
  }

  public persistPhoneNumber(phone: PhoneNumber, employeeId: string): Observable<any> {

    const [countryCode, number] = phone.Number ? phone.Number.split(' ') : [null, null];

    if (number) {
      const phoneColumns: TargetColumnValue[] = [
        { Name: 'CountryCode', Value: countryCode },
        { Name: 'Number', Value: number },
        { Name: 'Designation', Value: phone.Designation },
      ];
      const phonePersistQuery: TargetObjectData = new TargetObjectData();
      phonePersistQuery.ObjectKey = new ObjectKey();
      phonePersistQuery.ObjectKey.ObjectType = ObjectKeys.PERSONPHONENUMBER;
      phonePersistQuery.TargetColumns = phoneColumns;
      const persistObject: PersistObjectModel = new PersistObjectModel();
      persistObject.Object = phonePersistQuery;
      if (phone.Id) {
        phonePersistQuery.Mode = PersistMode.Update;
        phonePersistQuery.ObjectKey.Id = phone.Id;
      } else {
        phonePersistQuery.Mode = PersistMode.Insert;
        phoneColumns.push(
          { Name: 'ParentId', Value: employeeId },
          { Name: 'Type', Value: phone.Type },
          { Name: 'Show', Value: true }
        );
      }

      return this.persistenceService.executePersistObjectQuery(persistObject)
    } else {
      return of([])
    }
  }

  public onClosePopup(): void {
    this.closePopup.emit(true);
    if (!this.update) {
      this.pictureUploader.reset();
    }
  }

  public emptyData(): void {
    this.form.instance.resetValues();
    this.employee = new PersonViewModel(new Person(null));
  }

  public returnDxItemCssClass(className: string): string {
    return className;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
