import { Address, GeoDsDocument, GeoDsMeta } from "@geods/base";
import { EcoLibrary } from "./eco-library.model";

export class Machine extends GeoDsMeta {
    Name: string;
    Type: string;
    ConstructionYear: number;
    Control: string;
    Designation: string;
    Description: string;
    ManufacturerCompany: string;
    EcoLibrary: EcoLibrary;
    Image: GeoDsDocument;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}