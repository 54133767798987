import { Injectable } from '@angular/core';
import { Machine, Material, Tool } from '@ecoroll/models';
import {
  GeoDsCoreDataService,
  GeoDsPersistenceService,
  PersistObjectsModel,
  ReadListDataSourceModel
} from '@wissenswerft/core/data';
import {
  ObjectKey,
  PersistMode,
  PersistObjectModel,
  Query,
  QueryColumn,
  QueryObjectsModel,
  TargetColumnValue,
  TargetObjectData,
  QueryColumnSortOrder
} from '@wissenswerft/core/data';
import { ResourceManager } from '@wissenswerft/core/resources';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Address } from '@geods/base';

interface Condition {
  opath: string;
  name: string;
  format?: string;
  sortOrder?: QueryColumnSortOrder;
}
interface readInfoColumn {
  Name: string;
  DataType: string;
  DataLength: number;
  DisplayText: string;
}

interface readInfoResponse {
  Name: string;
  Namespace: string;
  DisplayText: string;
  DisplayTextPlural: string;
  UniqueDisplayText: string;
  Value: number;
  Columns: Array<readInfoColumn>;
}

export interface nestedObjectInput {
  modelName: string,
  ObjectQueries?: nestedObjectInput[],
  condition?: string, // condition
  specialColumns?: Array<Condition>,
  sortOrder?: Map<string, QueryColumnSortOrder>,
  Opath?: string, // for nested object queries
  Name?: string, // for nested object queries
  columns?: Array<string>,
}

export interface persistMultipleInputType {
  insert?: insertMultipleInputType[];
  update?: updateMultipleInputType[];
  delete?: deleteMultipleInputType[];
}
export interface insertMultipleInputType {
  modelName: string;
  data: any[];
  SeqNr?: number;
}
export interface updateMultipleInputType {
  modelName: string;
  data: any[];
}
export interface deleteMultipleInputType {
  modelName: string;
  data: string[];
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private readonly objectSpaceName = 'ABC.Applications.KAOS.Data';
  public res = ResourceManager.getResources('eco');
  public currentUserId: string;
  public companyData: Map<string, string> = new Map<string, string>();
  public companyName = new Subject<string>();
  public cachedToolType = [];
  public companyName$ = this.companyName.asObservable();

  public machines: Machine[] = [];
  public addresses: Address[] = [];
  public materials: Material[] = [];
  public tools: Tool[] = [];


  constructor(
    private coreDataService: GeoDsCoreDataService,
    private persistenceService: GeoDsPersistenceService
  ) { }

  public prepareCompanyData(id, companyName) {
    this.companyData.set('id', id);
    this.companyData.set('companyName', companyName);
    this.companyName.next(companyName);
  }

  public loadListDataSource(
    objectType: string,
    columnName: string
  ): Observable<any> {
    const readListDataSourceQuery = new ReadListDataSourceModel();
    const objectKey = new ObjectKey();
    objectKey.ObjectType = objectType;
    readListDataSourceQuery.ObjectKey = objectKey;
    readListDataSourceQuery.OutputMode = 0;
    readListDataSourceQuery.ColumnName = columnName;
    return this.coreDataService.executeReadListDataSource(
      readListDataSourceQuery
    );
  }

  public readObjects(
    objectType: string,
    columns: QueryColumn[],
    OPath?: string
  ): Observable<any> {
    const readObjectQuery: QueryObjectsModel = new QueryObjectsModel();
    const mainQuery: Query = new Query();
    mainQuery.ObjectType = objectType;
    mainQuery.Columns = columns;
    mainQuery.OPath = OPath;
    if (OPath) {
      mainQuery.OPath = OPath;
    }
    readObjectQuery.ObjectQueries = [mainQuery];
    return this.coreDataService.executeReadObjectsQuery(readObjectQuery);
  }

  public persistObject(
    mode: PersistMode,
    objectKey: ObjectKeys,
    columns?: TargetColumnValue[],
    id?: string
  ): Observable<ArrayBuffer> {
    const persistQuery: PersistObjectModel = new PersistObjectModel();
    persistQuery.ObjectSpaceName = this.objectSpaceName;
    const targetObject: TargetObjectData = new TargetObjectData();
    targetObject.Mode = mode;
    targetObject.ObjectKey = new ObjectKey();
    if (mode === PersistMode.Update) {
      targetObject.ObjectKey.Id = id;
    }
    targetObject.ObjectKey.ObjectType = objectKey;
    targetObject.TargetColumns = columns;
    persistQuery.Object = targetObject;
    return this.persistenceService.executePersistObjectQuery(persistQuery);
  }

  public deleteObject(objectKey: ObjectKeys, id: string): Observable<any> {
    const persistQuery: PersistObjectModel = new PersistObjectModel();
    persistQuery.ObjectSpaceName = this.objectSpaceName;
    const targetObject: TargetObjectData = new TargetObjectData();
    targetObject.Mode = PersistMode.Delete;
    targetObject.ObjectKey = new ObjectKey();
    targetObject.ObjectKey.Id = id;
    targetObject.ObjectKey.ObjectType = objectKey;
    persistQuery.Object = targetObject;
    return this.persistenceService.executePersistObjectQuery(persistQuery);
  }

  public geoDsPersistBody(data: object): TargetColumnValue[] {
    const CONFIG_PROPERTIES_TO_NOT_BE_PERSISTED = [
      'Id',
      'SysTimeInsert',
      'SysTimeUpdate',
      'SysUserNameInsert',
      'SysDateInsert',
      'SysDateUpdate',
      'SysUserNameUpdate',
      'ObjectType'
    ];

    const targetColumnsValues: TargetColumnValue[] = [];

    Object.keys(data).forEach((key) => {
      if (!CONFIG_PROPERTIES_TO_NOT_BE_PERSISTED.includes(key)) {
        const column = { Name: key, Value: data[key] };
        targetColumnsValues.push(column);
      }
    });
    return targetColumnsValues;
  }

  public geoDsReadObjectColumns(
    modelProperties: Array<string>,
    conditions?: Array<Condition>,
    sortOrder?: Map<string, QueryColumnSortOrder>
  ): Observable<QueryColumn[]> {
    const columns: QueryColumn[] = [];
    if (sortOrder && sortOrder.size > 0) {
      modelProperties.forEach((key) => {
        if (sortOrder.get(key)) {
          columns.push(
            this.coreDataService.createQueryColumn(key, key, sortOrder.get(key))
          );
        } else {
          columns.push(this.coreDataService.createQueryColumn(key, key));
        }
      });
    } else {
      modelProperties.forEach((key) => {
        columns.push(this.coreDataService.createQueryColumn(key, key));
      });
    }
    if (conditions && conditions.length > 0) {
      conditions.forEach((condition) => {
        columns.push(
          this.coreDataService.createQueryColumn(
            condition.opath,
            condition.name,
            condition.sortOrder ? condition.sortOrder : null,
            condition.format
          )
        );
      });
    }

    return of(columns);
  }

  public readObjectsWithColumns<T>(
    modelName: string,
    opath?: string,
    conditions?: Array<Condition>,
    sortOrder?: Map<string, QueryColumnSortOrder>
  ): Observable<T[]> {
    return this.coreDataService
      .executeReadObjectTypeInfo({ ObjectTypeName: modelName })
      .pipe(
        switchMap((data: readInfoResponse) => {
          const modelproperties = data.Columns.map(
            (column) => column.DisplayText
          );
          return this.geoDsReadObjectColumns(
            modelproperties,
            conditions,
            sortOrder
          ).pipe(
            switchMap((columns) => this.readObjects(modelName, columns, opath))
          );
        })
      );
  }

  public returnObjectColumns(
    modelName: string,
    conditions?: Array<Condition>,
    columns?: Array<string>,
    sortOrder?: Map<string, QueryColumnSortOrder>
  ): Observable<QueryColumn[]> {
    if (Array.isArray(columns) && columns.length > 0) {
      return this.geoDsReadObjectColumns(columns, conditions, sortOrder);
    } else {
      return this.coreDataService
        .executeReadObjectTypeInfo({ ObjectTypeName: modelName })
        .pipe(
          switchMap((data: readInfoResponse) => {
            const modelproperties = data.Columns.map(
              (column) => column.DisplayText
            );
            return this.geoDsReadObjectColumns(
              modelproperties,
              conditions,
              sortOrder
            );
          })
        );
    }
  }

  public prepareNestedObjectsColumns(
    objects: Map<string, Observable<QueryColumn[]>>,
    data: nestedObjectInput
  ): void {
    objects.set(
      this.generateKey(data),
      this.returnObjectColumns(
        data.modelName,
        data.specialColumns,
        data.columns,
        data.sortOrder
      )
    );
    if (data.ObjectQueries && data.ObjectQueries.length > 0) {
      data.ObjectQueries.forEach((query) => {
        this.prepareNestedObjectsColumns(objects, query);
      });
    }
  }

  generateKey(data: nestedObjectInput): string {
    return data.modelName + (data.Name ? data.Name : '')
  }

  public prepareNestedObjectsQueries(
    columnsData,
    data: nestedObjectInput,
    columnsNames: Array<string>
  ): Query {
    const query = new Query();
    const columnNameIndex = columnsNames.indexOf(
      this.generateKey(data)
    );
    query.Columns = columnsData[columnNameIndex];
    if (columnNameIndex === 0) {
      query.ObjectType = data.modelName;
    } else {
      query.Name = data.Name;
      query.OPath = data.Opath;
    }
    if (data.condition) {
      query.OPath = data.condition;
    }
    query.ObjectQueries = [];

    if (data.ObjectQueries && data.ObjectQueries.length > 0) {
      data.ObjectQueries.forEach((objectQuery) => {
        query.ObjectQueries.push(
          this.prepareNestedObjectsQueries(
            columnsData,
            objectQuery,
            columnsNames
          )
        );
      });
    }
    return query;
  }

  public readNestedObjectsWithColumns<T>(
    data: nestedObjectInput
  ): Observable<T[]> {
    const columnsObservables: Map<string, Observable<QueryColumn[]>> = new Map<
      string,
      Observable<QueryColumn[]>
    >();
    this.prepareNestedObjectsColumns(columnsObservables, data);

    const columnsNames = Array.from(columnsObservables.keys());

    const columnsData = forkJoin(Array.from(columnsObservables.values()));

    return columnsData.pipe(
      switchMap((columns) => {

        let query: Query = this.prepareNestedObjectsQueries(
          columns,
          data,
          columnsNames
        );
        const QueryDocument: QueryObjectsModel = new QueryObjectsModel();
        QueryDocument.ObjectQueries = [query];
        return this.coreDataService.executeReadObjectsQuery(QueryDocument);
      })
    );
  }

  private verifyDataArrayEmpty(data: insertMultipleInputType[] | updateMultipleInputType[] | deleteMultipleInputType[]): boolean {
    return data.every((element) => element.data.length === 0);
  }

  public persistMultipleData(data: persistMultipleInputType): Observable<any> {
    if (
      (!data.update ||
        (data.update &&
          Array.isArray(data.update) &&
          (data.update.length === 0 || this.verifyDataArrayEmpty(data.update)))) &&
      (!data.insert ||
        (data.insert &&
          Array.isArray(data.insert) &&
          (data.insert.length === 0 || this.verifyDataArrayEmpty(data.insert)))) &&
      (!data.delete ||
        (data.delete &&
          Array.isArray(data.delete) &&
          (data.delete.length === 0 || this.verifyDataArrayEmpty(data.delete))))
    ) {
      return of([]);
    } else {

      const query: PersistObjectsModel = new PersistObjectsModel();
      let persistQuery: TargetObjectData[][] = [[]];
      let index = -1;

      if (
        data.insert &&
        Array.isArray(data.insert) &&
        data.insert.length > 0 &&
        !this.verifyDataArrayEmpty(data.insert)
      ) {
        data.insert?.forEach((toInsert) => {
          let seqNr = Number.isFinite(toInsert.SeqNr) ? toInsert.SeqNr : null;
          if (
            toInsert.data &&
            Array.isArray(toInsert.data) &&
            toInsert.data.length > 0
          ) {
            index = index + 1;
            persistQuery[index] = [];
            toInsert.data.forEach((objectToInsert) => {
              let targetObject: TargetObjectData = new TargetObjectData();
              targetObject.Mode = PersistMode.Insert;
              targetObject.ObjectKey = new ObjectKey();
              targetObject.ObjectKey.ObjectType = toInsert.modelName;

              if (Number.isFinite(seqNr)) {
                seqNr = seqNr + 1;
                objectToInsert['SeqNr'] = seqNr;
              }
              const columns: TargetColumnValue[] =
                this.getTargetColumnValue(objectToInsert);
              targetObject.TargetColumns = columns;
              persistQuery[index].push(targetObject);
            })
          }
        });
      }

      if (
        data.update &&
        Array.isArray(data.update) &&
        data.update.length > 0 &&
        !this.verifyDataArrayEmpty(data.update)
      ) {
        data.update?.forEach((toUpdate) => {
          if (
            toUpdate.data &&
            Array.isArray(toUpdate.data) &&
            toUpdate.data.length > 0
          ) {
            index = index + 1;
            persistQuery[index] = [];

            toUpdate.data.forEach((objectToUpdate) => {
              let targetObject: TargetObjectData = new TargetObjectData();
              targetObject.Mode = PersistMode.Update;
              targetObject.ObjectKey = new ObjectKey();
              targetObject.ObjectKey.Id = objectToUpdate.Id;
              targetObject.ObjectKey.ObjectType = toUpdate.modelName;

              const columns: TargetColumnValue[] =
                this.getTargetColumnValue(objectToUpdate);
              targetObject.TargetColumns = columns;

              persistQuery[index].push(targetObject);
            })
          }
        });
      }

      if (
        data.delete &&
        Array.isArray(data.delete) &&
        data.delete.length > 0 &&
        !this.verifyDataArrayEmpty(data.delete)
      ) {
        data.delete?.forEach((toDelete) => {
          if (
            toDelete.data &&
            Array.isArray(toDelete.data) &&
            toDelete.data.length > 0
          ) {
            index = index + 1;
            persistQuery[index] = [];

            toDelete.data.forEach((objectIdToDelete) => {
              let targetObject: TargetObjectData = new TargetObjectData();
              targetObject.Mode = PersistMode.Delete;
              targetObject.ObjectKey = new ObjectKey();
              targetObject.ObjectKey.ObjectType = toDelete.modelName;

              targetObject.ObjectKey.Id = objectIdToDelete;

              persistQuery[index].push(targetObject);
            })
          }
        });
      }
      query.Objects = persistQuery;
      return this.persistenceService.executePersistObjectsQuery(query);
    }
  }

  private getTargetColumnValue(data: Object): TargetColumnValue[] {
    const queryColumns: TargetColumnValue[] = [];
    Object.entries(data).forEach(([property, value]) => {
      if (value !== null && value !== undefined && this.isPrimitive(value) && property !== 'Id') {
        queryColumns.push({ Name: property, Value: value });
      }
    });
    return queryColumns;
  }

  private isPrimitive(test) {
    return test !== Object(test);
  }
  public readPickList(pickListName: string) {
    const language =
      sessionStorage.getItem('fixedCulture') === 'en' ? "1033/'" : "1031/'";

    const opath = "ref:ParentId='" + pickListName + '/';
    const columns: QueryColumn[] = [
      this.coreDataService.createQueryColumn('Value', 'Value'),
      this.coreDataService.createQueryColumn('Description', 'Description', QueryColumnSortOrder.Ascending)
    ];
    return this.readObjects(ObjectKeys.PICKLISTITEM, columns, opath + language);
  }

  public getPickListByName(
    name: string,
    language: number = 1031
  ): Observable<any> {
    const controlItemsColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id')
    ];
    const opath = `Name='${name}' AND Language='${language}'`;
    return this.readObjects(ObjectKeys.PICKLIST, controlItemsColumns, opath);
  }

  public getPickListtemsByListId(itemId: string): Observable<any> {
    const controlItemsColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Value', 'Value'),
      this.coreDataService.createQueryColumn('Description', 'Description', QueryColumnSortOrder.Ascending)
    ];
    const opath = `ParentId='${itemId}'`;
    return this.readObjects(
      ObjectKeys.PICKLISTITEM,
      controlItemsColumns,
      opath
    );
  }

  public getPickListItemsByPickListName(
    pickListName: string,
    language: number = 1031
  ): Observable<any> {
    const controlItemsColumns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Value', 'Value'),
      this.coreDataService.createQueryColumn('Description', 'Description', QueryColumnSortOrder.Ascending)
    ];
    return this.getPickListByName(pickListName, language).pipe(
      switchMap((pickList) => {
        const opath = `ParentId='${pickList[0].Id}'`;
        return this.readObjects(
          ObjectKeys.PICKLISTITEM,
          controlItemsColumns,
          opath
        );
      })
    );
  }

  public getLanguage() {
    if (sessionStorage.getItem('fixedCulture') == 'de') {
      return 'de';
    } else {
      return 'en';
    }
  }

  public deepClone<T>(obj: T): T {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((property) => this.deepClone(property)) as any;
    }

    const clonedObj = {} as T;
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        clonedObj[prop] = this.deepClone(obj[prop]);
      }
    }

    return clonedObj;
  }
}

export enum ObjectKeys {
  ECOLIBRARY = 'ecLibrary',
  ECOMACHINE = 'ecMachine',
  ECOMATERIAL = 'ecMaterial',
  ECOTEST = 'ecTest',
  ECOTESTREPORT = 'ecTestReport',
  ECOTESTREPORTPERSON = 'ecTestReportPerson',
  ECOTESTREPORTMACHINE = 'ecTestReportMachine',
  ECOTESTREPORTMATERIAL = 'ecTestReportMaterial',
  ECOTESTREPORTTOOL = 'ecTestReportTool',
  ECORESULTREPORT = 'ecResultReport',
  ECOTOOL = 'ecTool',
  ADDRESS = 'Address',
  PERSON = 'Person',
  PHONENUMBER = 'PhoneNumber',
  PERSONPHONENUMBER = 'ecPersonPhoneNumber',
  Document = 'Document',
  ECOHARDNESS = 'ecHardness',
  ECOHARDNESSMEASURE = 'ecHardnessMeasure',
  PICKLIST = 'Picklist',
  PICKLISTITEM = 'PicklistItem',
  WAVY = 'ecWavy',
  SELFVOLTAGE = 'ecSelfVoltage',
  SELFVOLTAGEMEASURE = 'ecSelfVoltageMeasure',
  MEASURINGDEVICE = 'ecMeasuringDevice',
  MEASURINGDEVICEREPORT = 'ecMeasuringDeviceReport',
  TARGETVALUE = 'ecTargetValue',
  TECHNOLOGY = 'ecTechnology',
  ROUGHNESS = 'ecRoughness',
  PROPERTY = 'Property'
}
