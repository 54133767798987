<form action="post" (submit)="createCustomer($event)">
    <dx-form #customerform id="customerform">
        <dxi-item class="floatingLabel">
            <dx-text-box name="customerName" labelMode="floating" [label]="dataService.res('Eco-Customers-Name')"
                [(value)]="customer.customerName">
            </dx-text-box>
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item class="floatingLabel">
                <dx-text-box name="customerMatchCode" labelMode="floating"
                    [label]="dataService.res('Eco-Customers-Contact-Match-Code')"
                    [(value)]="customer.customerMatchCode">
                </dx-text-box>
                <dxi-validation-rule type="required">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item class="floatingLabel">
                <dx-text-box name="customerNumber" mode="number" labelMode="floating"
                    [label]="dataService.res('Eco-Customers-Number')" [(value)]="customer.customerNumber">
                </dx-text-box>
                <dxi-validation-rule type="required">
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="1">
            <dxi-item class="floatingLabel">
                <dx-text-box name="customerEmail" labelMode="floating"
                    [label]="dataService.res('Eco-Customers-Contact-Mail')" [(value)]="customer.customerEmail">
                </dx-text-box>
                <dxi-validation-rule type="email">
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
        <dxi-item [cssClass]="returnDxItemCssClass('title-bloc-container')" itemType="group"
            style="margin-bottom: 0px; width: 100%; padding-right: 0px;">
            <dxi-item>
                <p class="bloc-title">{{ dataService.res('Eco-Customers-Phone') }}</p>
            </dxi-item>
        </dxi-item>
        <dxi-item [cssClass]="returnDxItemCssClass('phone-number')" itemType="group" [colCount]="15"
            style="width: 100%;">

            <ww-phone [formGroup]="phoneForm" [(isReady)]="isPhoneReady" [(value)]="customer.phoneNumber.Number"
                [dialCode]="customer.phoneNumber.CountryCode" [(countryISO)]="customer.phoneNumber.Designation"
                [key]="'customerPhone'"></ww-phone>

        </dxi-item>
        <dxi-item itemType="group" style="margin-bottom: 0px;">
            <dxi-item>
                <p class="bloc-title">{{ dataService.res('Eco-Customers-Address') }}</p>
            </dxi-item>
        </dxi-item>
        <dxi-item class="floatingLabel">
            <dx-text-box name="street" labelMode="floating" [label]="dataService.res('Eco-Customers-Street')"
                [(value)]="customer.street">
            </dx-text-box>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="3">
            <dxi-item class="floatingLabel">
                <dx-text-box name="zip" labelMode="floating" [label]="dataService.res('Eco-Customers-Zip')"
                    [(value)]="customer.zip">
                </dx-text-box>
            </dxi-item>
            <dxi-item class="floatingLabel">
                <dx-text-box name="city" labelMode="floating" [label]="dataService.res('Eco-Customers-City')"
                    [(value)]="customer.city">
                </dx-text-box>
            </dxi-item>
            <dxi-item class="floatingLabel">
                <dx-text-box name="country" labelMode="floating" [label]="dataService.res('Eco-Customers-Country')"
                    [(value)]="customer.country">
                </dx-text-box>
            </dxi-item>
        </dxi-item>


        <dxi-item itemType="group">
            <dxi-item>
                <p class="bloc-title">{{ dataService.res('Eco-Contact-Person') }}</p>
            </dxi-item>
        </dxi-item>
        <dxi-item itemType="group">
            <div class="grid-responses">
                <div fxLayout="column" fxLayoutAlign=" none">
                    <div fxLayout="row" fxLayoutAlign=" end">
                        <div style="font-weight: bold; margin-bottom: 20px;" fxLayout="column" fxFlex="80"
                            fxLayoutAlign="start">
                        </div>
                        <div fxLayout="column" fxFlex="20" fxLayoutAlign=" end">
                            <dx-button icon="add" class="button-plus" (onClick)="addRow()">
                            </dx-button>
                        </div>
                    </div>
                    <ww-grid #employeesGrid [id]="'customerGrid'" [height]="'200px'"
                        [(gridData)]="customersService.employees" class="employees-grid"
                        [columnsHeader]="assignedEmployeesColumns" [enableColumnChooser]="false"
                        [allowExportSelectedData]="false" [linkProperty]="'text'" [showBorders]="true"
                        [enableExport]="false" [showfilterrow]="false" [selectionMode]="'single'" [columnFilter]="false"
                        [showColumnLines]="false" [allowDeleting]="true" [allowUpdating]="true" [filterPanel]="false"
                        [editingMode]="'row'" [enableHeaderFilter]="false" [rowAlternationEnabled]="false"
                        [searchPanel]="false" [allowAdding]="false" (onSaved)="saveRow($event)" [groupable]="false"
                        [showRowLines]="false">
                    </ww-grid>

                </div>
            </div>
        </dxi-item>

        <dxi-item [cssClass]="returnDxItemCssClass('div-buttons')" itemType="group">
            <dxi-item [cssClass]="returnDxItemCssClass('btn-create')" itemType="button"
                [(buttonOptions)]="createButtonOptions">
            </dxi-item>
            <dxi-item [cssClass]="returnDxItemCssClass('btn-cancel')" itemType="button"
                [buttonOptions]="CancelButtonOptions">
            </dxi-item>
        </dxi-item>
    </dx-form>
</form>