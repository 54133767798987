import { GeoDsDocument, GeoDsMeta } from "@geods/base";

export class Hardness extends GeoDsMeta {
    HardnessX: number;
    HardnessY: number;
    BrinellHB10: number;
    BrinellHB2_5: number;
    BrinellHB30: number;
    BrinellHB5: number;
    RockwellFormA: number;
    RockwellFormB: number;
    RockwellFormC: number;
    VickersHV001: number;
    VickersHV0015: number;
    VickersHV002: number;
    VickersHV0025: number;
    VickersHV005: number;
    VickersHV01: number;
    VickersHV02: number;
    VickersHV03: number;
    VickersHV05: number;
    VickersHV1: number;
    VickersHV10: number;
    VickersHV100: number;
    VickersHV2: number;
    VickersHV20: number;
    VickersHV3: number;
    VickersHV30: number;
    VickersHV5: number;
    VickersHV50: number;
    ProtocolHardnessMeasurement: GeoDsDocument;


    constructor(data) {
        super();
        Object.assign(this, data);
    }
}