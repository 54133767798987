import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DocumentKind, GeoDsDocument } from '@geods/base';
import { DataService, ObjectKeys } from 'apps/eco-base/src/app/services/data.service';
import { Subscription } from 'rxjs';
import { PERSISTDATA, UploadService } from '../../../services/upload.service';

@Component({
  selector: 'app-eco-uploader',
  templateUrl: './eco-uploader.component.html',
  styleUrls: ['./eco-uploader.component.scss']
})
export class EcoUploaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string;
  @Input() documentKind: DocumentKind = DocumentKind.Bild;
  @Input() acceptedFileType: string;
  @Input() fileDesignation: string;
  @Input() object: ObjectKeys;
  @Input() objectName: string;
  @Input() document: GeoDsDocument;
  @Input() imgSource = '';
  @Input() fileName: string;
  @Input() conserveDataOnDestroy = false;
  @Input() titleFontSize: string = "16px";
  @Input() titleColor: string = "#333";
  @Input() titleFontWeight: number = 600;
  @Input() headerWidth: string = "100%";
  @Input() dropZoneWidth: string = "200px";
  @Input() dropZoneHeight: string = "200px";
  @Input() containFile: boolean;
  @Output() containFileChange = new EventEmitter();
  private subscriptions: Subscription[] = [];
  public imageType = DocumentKind.Bild;
  public docType = DocumentKind.Dokument;
  public uploadImage: GeoDsDocument = new GeoDsDocument(null);
  public isImageDeleted = false;
  public documentId = "";
  public parentId = "";
  public progressVisible = false;
  public progressValue = 0;
  public textVisible = true;
  public isDropZoneActive = false;

  constructor(
    public dataService: DataService,
    private uploadService: UploadService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.uploadService.uploadData$.subscribe((uploadData: GeoDsDocument) => {
        if (uploadData.Designation === this.fileDesignation) {
          this.uploadImage = uploadData;
          this.imgSource = 'data:image/png;base64,' + uploadData.Object.split(',')[1];
          this.isDropZoneActive = false;
          this.textVisible = false;
          this.isImageDeleted = false;
          this.fileName = uploadData.FileName;
        }
        this.cdr.detectChanges();
      }));

    this.subscriptions.push(
      this.uploadService.persistData$.subscribe((persistData: PERSISTDATA) => {
        if (persistData.designation === this.fileDesignation && this.object === persistData.object) {
          if (this.uploadImage.Object?.length > 0) {
            this.parentId = persistData.parentId;
            this.documentId = persistData.docId;
            const document = this.getDocument();
            this.uploadService.persistDocument(document).subscribe((data) => {
            }, error => {
              console.error(error)
            });
          }
          if (this.isImageDeleted) {
            this.uploadService.deleteDocument(persistData.docId);
          }
        }
      }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imgSource) {
      if (changes.imgSource.currentValue) {
        this.containFile = true;
        this.containFileChange.emit(this.containFile);
      } else {
        this.containFile = false;
        this.containFileChange.emit(this.containFile);
      }
    }

  }

  ngOnDestroy(): void {
    if (this.conserveDataOnDestroy) {
      if (this.uploadImage.Object?.length > 0) {
        const document = this.getDocument();
        const docs = this.uploadService.conservedDataToPersist.get(this.objectName);
        const docsToAdd = docs ? [...docs, document] : [document]
        this.uploadService.conservedDataToPersist.set(this.objectName, docsToAdd);
      }
      if (this.isImageDeleted) {
        this.uploadService.conservedDataToDelete.push(this.fileDesignation);
      }
    }
    this.reset();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public onDocumentChanged(event): void {
    this.uploadImage.Designation = this.fileDesignation;
    this.uploadService.onFileChanged(event, this.uploadImage);
    this.progressVisible = false;
    this.progressValue = 0;

    this.containFile = true;
    this.containFileChange.emit(this.containFile);
  }

  public onUploadStarted(event): void {
    this.textVisible = false;
    this.progressVisible = true;
  }

  public getDocument(): GeoDsDocument {
    let uploadDocument = new GeoDsDocument(null);
    uploadDocument = this.uploadImage;
    uploadDocument.ParentId = this.parentId;
    uploadDocument.ParentObjectType = this.object;
    uploadDocument.Kind = this.documentKind;
    uploadDocument.Id = this.documentId;
    return uploadDocument;
  }

  public deleteDocument(): void {
    this.uploadImage = new GeoDsDocument(null);
    this.isImageDeleted = true;

    this.containFile = false;
    this.containFileChange.emit(this.containFile);
  }

  public onDropZoneEnter(event): void {
    if (event.dropZoneElement.id === 'dropzone-external' + this.title) {
      this.isDropZoneActive = true;
    }
  }

  public onDropZoneLeave(event): void {
    if (event.dropZoneElement.id === 'dropzone-external' + this.title) {
      this.isDropZoneActive = false;
    }
  }

  public onProgress(event): void {
    this.progressValue = event.bytesLoaded / event.bytesTotal * 100;
  }

  public onUploaded(): void {
    this.textVisible = false;
    this.progressVisible = false;
    this.progressValue = 0;
  }

  public downloadFile() {
    let doc = this.document;
    if (this.acceptedFileType === '.stp') {
      var byte = this.base64ToArrayBuffer(doc.Object);
      var blob = new Blob([byte], { type: 'step' });
      const fileName: string = this.fileName;
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    } else {
      let pdfWindow = window.open("")
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(doc.Object) + "'></iframe>"
      )
    }
  }

  private base64ToArrayBuffer(_base64Str) {
    var binaryString = window.atob(_base64Str);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  private getMimeType(doc: GeoDsDocument, ext?: string) {
    const _extension = doc.FileName.split('.').pop();
    const extension = (ext || _extension).toLowerCase();
    switch (extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'JPG':
        return 'image/jpeg';
      case 'pdf' || 'zip':
        return 'application/' + extension;
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'txt':
        return 'text/plain';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'csv':
        return 'text/csv';
      default:
        const fileNameExt = doc.FileName?.split('.').pop();
        if (fileNameExt && doc.FileName?.indexOf('.') > -1) {
          return this.getMimeType(doc, fileNameExt);
        } else if (doc.FileName.indexOf('.') === -1) {
          if (doc.Kind) {
            switch (doc.Kind) {
              case DocumentKind.Bild:
                return 'image/jpeg';
              default:
                return 'application/octet-stream';
            }
          }
        } else {
          return 'application/octet-stream';
        }
    }
  }

  public reset(): void {
    this.imgSource = '';
    this.document = new GeoDsDocument(null);
    this.uploadImage = new GeoDsDocument(null);
    this.documentId = '';
    this.isImageDeleted = false;
    this.parentId = "";
    this.progressVisible = false;
    this.progressValue = 0;
    this.textVisible = true;
    this.isDropZoneActive = false;
  }

}
