import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@wissenswerft/core/authentication';
import { AppComponent } from './app.component';
import { CustomersComponent } from './core/customers/customers.component';
import { MachinesComponent } from './core/machines/machines.component';
import { EmployeesComponent } from './core/employees/employees.component';
import { TechnologyComponent } from './core/technology/technology.component';
import { MeasuringDevicesComponent } from './core/measuring-devices/measuring-devices.component';
import { PickListComponent } from './core/pick-list/pick-list.component';

const routes: Routes = [
    {
        path: 'app',
        component: AppComponent, canActivate: [AuthenticationGuard]
    },
    {
        path: 'experiments',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./core/experiments/experiments.module').then((module) => module.ExperimentsModule),
    },
    {
        path: 'customers',
        component: CustomersComponent, canActivate: [AuthenticationGuard],
        loadChildren: () => import('./core/customers/customers.module').then((module) => module.CustomersModule),
    },
    {
        path: 'machines',
        component: MachinesComponent, canActivate: [AuthenticationGuard],
        loadChildren: () => import('./core/machines/machines.module').then((module) => module.MachinesModule),
    },
    {
        path: 'employee',
        component: EmployeesComponent, canActivate: [AuthenticationGuard],
        loadChildren: () => import('./core/employees/employees.module').then((module) => module.EmployeesModule),
    },
    {
        path: 'materials', canActivate: [AuthenticationGuard],
        loadChildren: () => import('./core/materials/materials.module').then((module) => module.MaterialModule),
    },
    {
        path: 'tools',
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import('./core/tools/tools.module').then((module) => module.ToolsModule),
    },
    {
        path: 'technology',
        component: TechnologyComponent,
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import('./core/technology/technology.module').then((module) => module.TechnologyModule),
    },
    {
        path: 'measuring-devices',
        component: MeasuringDevicesComponent,
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import('./core/measuring-devices/measuring-devices.module').then((module) => module.MeasuringDevicesModule),
    },
    {
        path: 'pick-list',
        component: PickListComponent,
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import('./core/pick-list/pick-list.module').then((module) => module.PickListModule),
    },
    { path: '', redirectTo: '/test-reports', pathMatch: 'full' },
    {
        path: 'test-reports',
        loadChildren: () =>
            import('./core/test-reports/test-reports.module').then((module) => module.TestReportsModule),
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthenticationGuard]
})

export class AppRoutingModule { }