import { Address, GeoDsDocument, GeoDsMeta, Person } from "@geods/base";
import { Hardness } from "./hardness.model";
import { Machine } from "./machine.model";
import { Material } from "./material.model";
import { Roughness } from "./roughness.model";
import { SelfVoltage } from "./self-voltage.model";
import { Tool } from "./tool.model";
import { Wavy } from "./wavy.model";

export class EcoTest extends GeoDsMeta {
    Address: Address;
    Customer: Address;
    Machine: Machine;
    Tool: Tool;
    Designation: number;
    ApplicationTechnician: Person;
    BeforeRollingHardness: Hardness;
    ContactPerson: Person;
    Material: Material;
    Approximation: number;
    ProcessType: string;
    //AspectRatio: number;
    AttackAngle: number;
    AuxiliaryCosTau: number;
    BeatFrequency: number;
    BeatFrequencyUnit: string;
    Clamping: string;
    PreliminaryProcess: boolean
    CoefficientE: number;
    CoefficientEN: number;
    CoefficientN: number;
    CoefficientYE: number;
    ContactRadiusR11: number;
    ContactRadiusR12: number;
    ContactRadiusR21: number;
    ContactRadiusR22: number;
    Cooling: string;
    Coverage: number;
    Curvature11: number;
    Curvature12: number;
    Curvature21: number;
    Curvature22: number;
    CuttingCornerRadius: number;
    CuttingEdgeRadius: number;
    CuttingInsert: string;
    CuttingSpeed: number;
    DepthOfCut: number;
    DepthPenetrationResidualStressesPar: number;
    ExcessMultrolling: number;
    FeedLineOffset: number;
    FeedPitch: number;
    FeedRate: number;
    GeometryName: string;
    HalfWidthDepthCurve: number;
    HardeningDepthCurve: number;
    HertzianPressing: number;
    ImpactEnergy: number;
    ImprovementArithmeAvedRoughness: number;
    InfeedMechanicalTool: number;
    LargeContactSemiAxis: number;
    MachiningRadius: number;
    MachiningRadiusTransverseHalfDiameter: number;
    MachiningWorkpieceDiameter: number;
    MaxResidualCompressiveStressesPar: number;
    NumberOfBlades: number;
    ParameterSpeed: number;
    ProtocolHardnessMeasurement: string;
    ProtocolResidualStressMeasurement: string;
    RadiusOfCurvature1: number;
    RadiusOfCurvature2: number;
    CenterHeight: number;
    Remark: string;
    RemarkRollers: string;
    ResidualStressDepthCurve: string;
    ResidualStressDepthProfileTraverse: string;
    RollBody: string;
    RollerDiameter: number;
    RollerPrinting: number;
    RollingBallDiameter: number;
    RollingForce: number;
    RollingSpeed: number;
    RollingFeed: number;
    RollRadius: number;
    RollingResultHardness: Hardness;
    RotationalSpeed: number;
    RotationalSpeedTool: number;
    RollingResultSelfVoltage: SelfVoltage;
    BeforeRollingSelfVoltage: SelfVoltage;
    BeforeRollingRoughness: Roughness;
    RollingResultRoughness: Roughness;
    SmallContactSemiAxis: number;
    SpitsHeight: number;
    SurfaceResidualStressesPar: number;
    SurfaceResidualStressesTraverse: number;
    TiltAngle: number;
    TrialLength: number;
    WobbleAngle: number;
    WorkpieceLength: number;
    BeforeRollingWavy: Wavy;
    RollingResultWavy: Wavy;
    ParentId: string;
    RoughnessMeasurementProtocol: GeoDsDocument;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}



