import { GeoDsMeta } from "@geods/base";
import { EcoLibrary } from "./eco-library.model";
import { Hardness } from "./hardness.model";

export class Material extends GeoDsMeta {
    Condition: string;
    ConditionHeatTreatment: string;
    Density: number;
    DensityUnit: string;
    DesignationAFNOR: string;
    DesignationAlloy: string;
    DesignationEurope: string;
    DesignationGB: string;
    DesignationGer: string;
    DesignationGost: string;
    DesignationJIS: string;
    DesignationSIS: string;
    DesignationSS: string;
    DesignationUNE: string;
    DesignationUNI: string;
    DesignationUNS: string;
    DesignationUS: string;
    ElectricalResistance: number;
    Hardness: Hardness;
    MainGroup: string;
    MainGroupDescription: string;
    MainGroupMaching: string;
    MaterialName: string;
    MaterialNr: string;
    ModulusElasticity: number;
    ModulusElasticityUnit: string;
    SpecificHeatCapacity: number;
    StretchLimit: number;
    StretchLimitUnit: string;
    ThermalConductivity: number;
    TransverseContractionNbr: number;
    VarietyNbr: string;
    YieldStrength: string;
    YieldStrengthUnit: string;
    HardnessFullText: string;
    EcoLibrary: EcoLibrary;


    constructor(data) {
        super();
        Object.assign(this, data);
    }
}