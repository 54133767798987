import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EcoTest, TestReport } from '@ecoroll/models';
import { Address, PhoneNumber } from '@geods/base';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { DxPopupComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Column } from 'devextreme/ui/data_grid';
import { Workbook } from 'exceljs';
import { forkJoin, Subscription } from 'rxjs';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { CustomerViewModel } from '../view-models/customer.view-model';
import { CreateCustomerComponent } from './create-customer/create-customer.component';
import { CustomersService } from './customers.service';
import saveAs from 'file-saver';
import { PersonViewModel } from '../view-models/person.view-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'ecoBase-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, OnDestroy {
  @ViewChild("customerGrid") customerGrid: GridComponent;
  @ViewChild("addCustomerPopup") addCustomerPopup: DxPopupComponent;
  @ViewChild('createForm') createForm: CreateCustomerComponent;
  @ViewChild("deleteCustomerPopup") deleteCustomerPopup: DxPopupComponent;

  public selectedCustomerId = "";
  public showLoader = true;
  public isDeleteWarning = false;
  public warningMsg = '';
  public customers: CustomerViewModel[] = [];
  private subscriptions: Subscription[] = [];
  public customerContact: PersonViewModel[] = [];
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Eco-Customers-Columns-Header-Nr'),
      dataField: 'customerNumber',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Eco-Customers-Columns-Header-Erstelldatum'),
      dataField: 'creationDate',
      dataType: 'string',
      visibleIndex: 1
    },
    {
      caption: this.dataService.res('Eco-Customers-Columns-Header-Name'),
      dataField: 'customerName',
      dataType: 'string',
      visibleIndex: 2
    },
    {
      caption: this.dataService.res('Eco-Customers-Columns-Header-Address'),
      dataField: 'address',
      dataType: 'string',
      visibleIndex: 3
    },
    {
      caption: this.dataService.res('Eco-Customers-Contact'),
      dataField: 'contactPersons',
      dataType: 'string',
      visibleIndex: 4,
      calculateFilterExpression: (value, selectedFilterOperations) => {
        if (value) {
          return [this.getPerson, "contains", value];
        }
      },
      lookup: {
        dataSource: this.customerContact,
        valueExpr: 'id',
        displayExpr: 'fullName'
      },
      cellTemplate: this.cellTemplate
    },
    {
      caption: this.dataService.res('Eco-Customers-Columns-Header-Contact-PhoneNumber'),
      dataField: 'customerPhone',
      dataType: 'string',
      visibleIndex: 5
    },
    {
      caption: this.dataService.res('Eco-Customers-Columns-Header-Contact-Mail'),
      dataField: 'customerEmail',
      dataType: 'string',
      visibleIndex: 6
    },
    {
      type: 'buttons',
      caption: '',
      alignment: 'left',
      minWidth: 70,
      buttons: [
        {
          icon: 'edit',
          text: 'Edit',
          onClick: (event) => { this.openCustomerDialog(event) }
        },
        {
          icon: 'trash',
          text: this.dataService.res('Eco-Delete'),
          onClick: (event) => { this.openDeleteCustomerDialog(event) }
        }
      ]
    }
  ];

  constructor(
    public dataService: DataService,
    private appService: AppService,
    private customersService: CustomersService
  ) { }

  ngOnInit(): void {
    const opath = "Name <> 'ECOROLL'";
    this.subscriptions.push(
      this.dataService.readNestedObjectsWithColumns<Address>(
        {
          modelName: ObjectKeys.ADDRESS,
          condition: opath,
          ObjectQueries: [
            {
              modelName: ObjectKeys.PERSON, Opath: "Persons", Name: "Persons",
              columns: [
                "Id",
                "FirstName",
                "LastName",
                "EMail",
                "PersonnelNumber"
              ],
              ObjectQueries: [
                { modelName: ObjectKeys.PHONENUMBER, Opath: "PhoneNumbers", Name: "PhoneNumbers" }
              ]
            },
            { modelName: ObjectKeys.PHONENUMBER, Opath: "PhoneNumbers", Name: "PhoneNumbers" }
          ]
        }
      ).subscribe((customers) => {
        if (customers.length > 0) {
          customers.forEach((customer, index) => {
            if (customer.Persons) {
              customer.Persons.forEach(res => {
                if (!this.customerContact.find(e => e.firstName === res.FirstName)) {
                  this.customerContact.push(new PersonViewModel(res));
                }
              });
            }

            if (!(customer.PhoneNumbers && customer.PhoneNumbers.length > 0)) {
              customer.PhoneNumbers = [new PhoneNumber(null)];
            }

            this.customers.push(new CustomerViewModel(customer));
            if (index + 1 === customers.length) {
              this.showLoader = false;
            }
          }, error => {
            console.error(error);
            this.showLoader = false;
          });
        } else {
          this.showLoader = false;
        }
      }, (error) => {
        this.showLoader = false;
        this.appService.callNotification({
          message: this.dataService.res('Eco-Error'),
          type: ToastType.ERROR
        });
      }))
  }

  public onInitialized(e): void {
    e.component.option('syncLookupFilterValues', false);
  }

  private getPerson(rowData) {
    let personIds = '';
    if (rowData._persons) {
      rowData._persons.forEach(person => {
        personIds = personIds + (person.id).toString();
      });
      return personIds;
    }
    return null;
  }

  private cellTemplate(container, options) {
    const datatoDisplay = [];
    (options.value ?? []).map((element) => {
      datatoDisplay.push(element.firstName);
    });
    datatoDisplay.forEach((element) => {
      let div = document.createElement("div");
      let p = document.createElement("p");
      p.append(element.toString());
      p.style.margin = '0px 0px 0px 4px';
      p.style.padding = '3px 6px 3px 6px';
      p.style.backgroundColor = '#ddd';
      p.style.borderRadius = '2px';
      p.style.color = '#333';
      p.style.display = "inline-block";
      p.style.position = "relative";
      p.style.textAlign = "center";
      div.append(p);
      div.style.display = "inline-block";
      container.append(div);
    })

  }

  public openDeleteCustomerDialog(event): void {
    this.selectedCustomerId = event.row.data.id;
    let opath = `IdRefAddressCustomer='${this.selectedCustomerId}'`;
    this.subscriptions.push(
      forkJoin([
        this.dataService.readNestedObjectsWithColumns<EcoTest>(
          { modelName: ObjectKeys.ECOTEST, condition: opath, columns: ['Id'] }
        ),
        this.dataService.readNestedObjectsWithColumns<TestReport>(
          { modelName: ObjectKeys.ECOTESTREPORT, condition: opath, columns: ['Id'] }
        )
      ])
        .subscribe(([tests, reports]) => {
          if (tests?.length > 0) {
            this.isDeleteWarning = true;
            this.warningMsg = "Eco-Delete-Msg-Warning-Tests";
          }
          if (reports?.length > 0) {
            this.isDeleteWarning = true;
            this.warningMsg = "Eco-Delete-Msg-Warning-Reports";
          }
          this.deleteCustomerPopup.instance.show();
        }, (error) => {
          this.deleteCustomerPopup.instance.show();
        })
    )
  }

  public openCustomerDialog(event?): void {
    if (event?.row.data) {

      let customerVM: CustomerViewModel = event?.row.data
      const customClone: Address = this.dataService.deepClone({ ...customerVM }['customer'])

      if (!customClone.PhoneNumbers) {
        customClone.PhoneNumbers = [new PhoneNumber(null)];
      }

      if (customClone.PhoneNumbers && !customClone.PhoneNumbers[0].Designation) {
        customClone.PhoneNumbers[0].Designation = CountryISO.Germany;
      }
      this.createForm.customer = new CustomerViewModel(customClone);
      this.customersService.employees = [...customerVM.contactPersons]
      this.createForm.phoneForm = new FormGroup({
        phone: new FormControl(event.row.data.phoneNumber.Number, [Validators.required]),
      });
      this.createForm.update = true;
    } else {
      const phoneNumber = new PhoneNumber(null)
      const custumer = new CustomerViewModel(new Address({ PhoneNumbers: [{ ...phoneNumber, Designation: CountryISO.Germany }] }));
      this.createForm.customer = custumer;
      this.customersService.employees = [];
      this.createForm.update = false;
      this.createForm.phoneForm = new FormGroup({
        phone: new FormControl(undefined, [Validators.required]),
      });
      this.createForm.form.instance.resetValues();
    }
    this.createForm.isPhoneReady = true;
    this.addCustomerPopup.instance.show();
  }

  public onCloseDeletePopup(): void {
    this.isDeleteWarning = false;
    this.deleteCustomerPopup.instance.hide();
  }

  public closePopup(): void {
    this.createForm.isPhoneReady = false;
    this.addCustomerPopup.instance.hide();
  }

  public onHidden(event) {
    this.createForm.onClosePopup();
  }

  public deleteCustomer(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.ADDRESS, this.selectedCustomerId)
        .subscribe(
          (deletedCustomer) => {
            if (deletedCustomer?.Id) {
              this.customers = this.customers.filter(
                (customer) => customer.id != deletedCustomer?.Id
              );
              this.appService.callNotification({
                message: this.dataService.res('Eco-Success'),
                type: ToastType.SUCCESS
              });
            }
          },
          (error) => {
            this.appService.callNotification({
              message: this.dataService.res('Eco-Delete-Msg-Warning'),
              type: ToastType.ERROR
            });
          }, () => {
            this.onCloseDeletePopup();
          }
        )
    );
  }
  public createCustomerOutput(event: { isUpdated: boolean, Id: string }): void {
    if (event.Id) {
      let opath = 'Id=' + "'" + event.Id + "'";
      this.dataService.readNestedObjectsWithColumns<Address>(
        {
          modelName: ObjectKeys.ADDRESS,
          condition: opath,
          ObjectQueries: [
            { modelName: ObjectKeys.PERSON, Opath: "Persons", Name: "Persons" },
            { modelName: ObjectKeys.PHONENUMBER, Opath: "PhoneNumbers", Name: "PhoneNumbers" }
          ]
        }
      )
        .subscribe((customer) => {
          const customerModel = customer[0];

          if (!customerModel.PhoneNumbers) {
            const phoneNumber = new PhoneNumber(null)
            customerModel.PhoneNumbers = [{ ...phoneNumber, Designation: CountryISO.Germany }]
          }

          const persistedCustomer = new CustomerViewModel(customer[0]);
          if (event.isUpdated) {
            let selectedIndex = this.customers.findIndex((customer) => customer.id === event.Id);
            this.customers[selectedIndex] = persistedCustomer;
            this.showLoader = false;
          } else {
            this.customers.unshift(persistedCustomer);
            this.showLoader = false;
          }
        })
    }
  }

  public onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'contactPersons') {
            let value = [];
            if (gridCell.value && gridCell.value.length > 0) {
              value.push(...gridCell.value.map((person) => person.fullName))
            }

            excelCell.value = value ? value.join(' --- ') : "";
          } else {
            excelCell.value = gridCell.value;
          }
        }
      }
    }).then(function () {
      workbook.xlsx.writeBuffer()
        .then(function (buffer: BlobPart) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        });
    });
    e.cancel = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
