<div fxLayout="column" class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
  <div class="content-block">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <h2 class="title-style">{{dataService.res('Eco-Technologies')}}</h2>
      </div>
      <div fxLayout="column" class="add-button-style">
        <dx-speed-dial-action icon="add" (onClick)="openTechnologyDialog()">
        </dx-speed-dial-action>
      </div>
    </div>
    <div class="dx-card responsive-paddings">
      <dx-data-grid class="grid-group-by-style" [id]="'technologies'" [dataSource]="technologies" [height]="'100%'"
        (onExporting)="onExporting($event)" dataRowTemplate="dataRowTemplateName" [showBorders]="true"
        [showRowLines]="false" [showColumnLines]="false" [hoverStateEnabled]="true">
        <dxo-selection mode="single"></dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" [title]="dataService.res('Eco-Search')">
        </dxo-search-panel>
        <dxi-column [caption]="dataService.res('Eco-Technologies-Image')" [allowExporting]="false"
          [width]="'20%'"></dxi-column>
        <dxi-column [caption]="dataService.res('Eco-Technologies-Name')" dataField="name" [width]="'20%'">
        </dxi-column>
        <dxi-column [caption]="dataService.res('Eco-Technologies-Description')" dataField="description" [width]="'20%'"
          [allowFiltering]="false" [allowEditing]="false">
        </dxi-column>

        <dxi-column type="buttons">
        </dxi-column>
        <ng-container *dxTemplate="let technology of 'dataRowTemplateName'">
          <tr style="border-bottom: 1px solid #ddd;">
            <td rowspan="1" class="td-border">
              <div class="technology-bloc">
                <img class="technology-img" *ngIf="technology.data.image" [src]="technology.data.image" />
                <img class="technology-img" *ngIf="!technology.data.image" src="assets/images/no-image.png" />
              </div>
            </td>
            <td class="grid-text-style technology-name">{{technology.data.name }}</td>
            <td class="grid-text-style">
              <dx-text-area [readOnly]="true" [height]="'150px'" labelMode="hidden"
                [value]="technology.data.description">
              </dx-text-area>
            </td>
            <td rowspan="2">
              <div class="td-action">
                <img (click)="openTechnologyDialog(technology)" class="grid-action" src="assets/images/edit.svg">
                <img (click)="openDeleteTechnologyDialog(technology)" class="grid-action"
                  src="assets/images/delete.svg">
              </div>
            </td>
          </tr>
        </ng-container>
      </dx-data-grid>
    </div>
  </div>
</div>
<dx-popup #addTechnologyPopup class="popup-window-style" title="{{dataService.res('Eco-Technology')}}" [width]="'50%'"
  [height]="'auto'" [showCloseButton]="true" [showCloseButton]="'true'">
  <ecoBase-create-technology #createForm (closePopup)="closePopup()" (createTechnology)="createTechnology($event)">
  </ecoBase-create-technology>
</dx-popup>
<dx-popup #deleteTechnologyPopup [width]="'25%'" [showTitle]="false" fxLayout="column" fxLayoutAlign="center center"
  [height]="'90%'" [showCloseButton]="true">
  <div class="delete-popup">
    <div>{{dataService.res('Eco-Technologies-Delete-Msg')}}</div>
    <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
      <dx-button class="btn-create" [text]="dataService.res('Eco-Delete')" (onClick)="deleteTechnology()">
      </dx-button>
      <dx-button class="btn-cancel" [text]="dataService.res('Eco-Cancel')" (onClick)="onCloseDeletePopup()">
      </dx-button>
    </dxi-item>
  </div>
</dx-popup>

<ecoBase-eco-loader *ngIf="showLoader"></ecoBase-eco-loader>