import { Injectable } from '@angular/core';
import { DocumentKind, GeoDsDocument } from '@geods/base';
import { GeoDsCoreDataService, GeoDsPersistenceService, IPersistable, ObjectKey, PersistMode, PersistObjectModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { Observable, Subject } from 'rxjs';
import { ObjectKeys } from './data.service';
import { ImageObject } from '../core/shared/eco-multiple-uploader/eco-multiple-uploader.models';

export interface PERSISTDATA {
    object: ObjectKeys,
    designation: string,
    parentId: string,
    docId: string
}

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    private uploadData: Subject<GeoDsDocument> = new Subject();
    public uploadData$: Observable<GeoDsDocument> = this.uploadData.asObservable();
    private uploadMultipleData: Subject<GeoDsDocument> = new Subject();
    public uploadMultipleData$: Observable<GeoDsDocument> = this.uploadMultipleData.asObservable();
    private readonly reportTemplateId = 'e3696dca-79d5-424a-8512-d4a2955db0fa';
    private persistData: Subject<PERSISTDATA> = new Subject();
    public persistData$: Observable<PERSISTDATA> = this.persistData.asObservable();
    public conservedDataToPersist: Map<string, Array<GeoDsDocument>> = new Map<string, Array<GeoDsDocument>>();
    public conservedDataToDelete: Array<string> = [];
    public multipleFilesToUpload: Array<GeoDsDocument> = [];
    public shownImages: ImageObject[] = [];
    public multipleFilesIdToDelete: Array<string> = [];

    public basicDataMultipleFilesToUpload: Array<GeoDsDocument> = [];
    public basicDataShownImages: ImageObject[] = [];
    public basicDataMultipleFilesIdToDelete: Array<string> = [];

    constructor(private persistenceService: GeoDsPersistenceService,
        private coreDataService: GeoDsCoreDataService,) { }

    public persistDocument(document: GeoDsDocument, preparedDocObject: boolean = false): Observable<any> {
        const documentPersistQuery: TargetObjectData = new TargetObjectData();
        documentPersistQuery.ObjectKey = new ObjectKey();
        documentPersistQuery.ObjectKey.ObjectType = ObjectKeys.Document;

        const documentColumns: TargetColumnValue[] = [
            { Name: 'Object', Value: preparedDocObject ? document?.Object : this.getBinaryStringFromUrl(document?.Object) },
            { Name: 'Designation', Value: document?.Designation },
            { Name: 'FileName', Value: document?.FileName },
            { Name: 'ParentId', Value: document?.ParentId },
            { Name: 'Kind', Value: document?.Kind },
            { Name: 'Comment', Value: document?.Comment },
            { Name: 'ParentObjectType', Value: document?.ParentObjectType }
        ];
        if (document?.Id !== undefined) {
            documentPersistQuery.ObjectKey.Id = document?.Id;
            documentPersistQuery.Mode = PersistMode.Update;
        } else {
            documentPersistQuery.Mode = PersistMode.Insert;
        }
        documentPersistQuery.TargetColumns = documentColumns;
        const persistObject: PersistObjectModel = new PersistObjectModel();
        persistObject.Object = documentPersistQuery;
        return this.persistenceService.executePersistObjectQuery(persistObject);
    }

    private getBinaryStringFromUrl(url: string): string {
        return url.split(',')[1];
    }

    public deleteDocument(documentId: string): void {
        const objectKey: IPersistable = {
            id: documentId,
            objectType: 'Document',
            persistenceCategory: null,
            changesPersisted: (persistMode) => (persistMode = PersistMode.Delete),
        };
        this.persistenceService.clearPendingObjects();
        this.persistenceService.addObjectForDelete(objectKey);
        this.persistenceService.persistPendingChanges();
    }

    public onFileChanged(e, document: GeoDsDocument, multiple: boolean = false): void {
        let documentSource = "";
        let fileName = "";
        if (e?.value !== null) {
            if (multiple) {
                const files: any[] = Array.from(e.target.files);
                files.forEach(file => {
                    const reader = new FileReader();
                    reader.readAsDataURL(new Blob([file]));
                    let doc = { ...document }
                    reader.onloadend = () => {
                        documentSource = reader.result as string;
                        doc.Object = documentSource;
                        doc.FileName = file?.name !== undefined ? file?.name : '';
                        this.uploadMultipleData.next(doc);
                    };
                });

            } else {
                const file = e?.value[0];
                if (file?.name !== undefined) {
                    fileName = file.name;
                }
                const reader = new FileReader();
                reader.readAsDataURL(new Blob([file]));
                reader.onloadend = () => {
                    documentSource = reader.result as string;
                    document.Object = documentSource;
                    document.FileName = fileName;
                    this.uploadData.next(document);
                };
            }

        }
    }

    public onLaunchPersist(peristData: PERSISTDATA): void {
        this.persistData.next(peristData);
    }

    public downloadFile(doc: GeoDsDocument) {
        const a = document.createElement('a');
        a.href = `data:${this.getMimeType(doc)};base64,${doc.Object}`;
        a.download = doc.Designation;
        a.click();
    }

    public getMimeType(doc: GeoDsDocument, ext?: string) {
        const _extension = doc.FileName.split('.').pop();
        const extension = (ext || _extension).toLowerCase();
        switch (extension) {
            case 'png':
                return 'image/png';
            case 'jpg':
            case 'JPG':
                return 'image/jpeg';
            case 'pdf' || 'zip':
                return 'application/' + extension;
            case 'doc':
                return 'application/msword';
            case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'pptx':
                return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            case 'txt':
                return 'text/plain';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'csv':
                return 'text/csv';
            case 'stp':
                return 'stp';
            default:
                const fileNameExt = doc.FileName?.split('.').pop();
                if (fileNameExt && doc.FileName?.indexOf('.') > -1) {
                    return this.getMimeType(doc, fileNameExt);
                } else if (doc.FileName.indexOf('.') === -1) {
                    if (doc.Kind) {
                        switch (doc.Kind) {
                            case DocumentKind.Bild:
                                return 'image/jpeg';
                            default:
                                return 'application/octet-stream';
                        }
                    }
                } else {
                    return 'application/octet-stream';
                }
        }
    }

    public getGeoDsDocumentFromInsertedDocument(doc: object, parentId: string): GeoDsDocument {
        let geoDSDocument = new GeoDsDocument({
            Object: null,
            Designation: null,
            FileName: null,
            Kind: null,
            ParentObjectType: null,
        });

        let result: GeoDsDocument = Object.assign(
            geoDSDocument,
            ...Object.keys(geoDSDocument).map(k => k in doc && { [k]: doc[k] })
        );
        result.ParentId = parentId;
        return result
    }

    public duplicateDocument(doc: object, parentId: string): void {
        if (doc) {
            let documentToBePersisted = this.getGeoDsDocumentFromInsertedDocument(doc, parentId);
            this.persistDocument(documentToBePersisted, true).subscribe((data) => {
            }, error => {
                console.error(error)
            });;
        }
    }

    public downloadReport = (reportId: string): Observable<any> => {
        const query = {
            Format: 'Pdf',
            Id: this.reportTemplateId,
            Parameters: [
                {
                    Name: 'ReportId',
                    Value: reportId,
                },
            ],
        };
        return this.coreDataService.exportDocumentResult(query);
    }
}