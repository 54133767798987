import { GeoDsMeta } from "@geods/base";
import { GeoDsDocument } from "@geods/base";
import { EcoLibrary } from "./eco-library.model";
import { Material } from "./material.model";

export class Tool extends GeoDsMeta {
    AttackAngleInTool: number;
    AttackAngleOfTool: number;
    BeateHeadRadius: number;
    BeatersNumber: number;
    Coolant: string;
    Cooling: boolean;
    CurvatureRadius1: number;
    CurvatureRadius2: number;
    PunchHeadRadius1: number;
    PunchHeadRadius2: number;
    CuttingEdgesNbr3M2M: number;
    CuttingPlate: string;
    Description: string;
    DegreeOfCoverage: number;
    Feed: number;
    FeedRate: number;
    HammerInsert: number;
    ImpactDistance: number;
    ImpactEnergy: number;
    ImpactFrequency: number;
    LineOffset: string;
    MachiningDiameter: number;
    ModulusElasticityTool: number;
    ModulusElasticityToolUnit: string;
    Name1: string;
    Name2: string;
    Name3: string;
    Name4: string;
    OversizeDiameter: number;
    PoissonRatio: number;
    RollBodyNr: string;
    RollBodySurface: string;
    RollerDiameter: number;
    RollersNbr: number;
    RollingBallDiameter: number;
    RollingBallInsertHGx: string;
    RollingBallRadius: number;
    RollingPressure: number;
    RollingSpeed: number;
    RollingForce: number;
    RollRadius: number;
    SpeedComponent: number;
    TiltAngle: number;
    ToolGeometry: string;
    ToolInfeed: string;
    ToolNr: string;
    ToolRollers: string;
    ToolSpeed: number;
    ToolName: string;
    TransverseContractionNbr: number;
    Type: string;
    WobbleAngle: number;
    Material: Material[];
    EcoLibrary: EcoLibrary;
    ImageTool: GeoDsDocument;
    ImageModelTool: GeoDsDocument;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}