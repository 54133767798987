<div fxLayout="column" class="content-style-class" [style.filter]="showLoader == true ? 'blur(0.2rem)' : 'blur(0)'">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="title-style">{{dataService.res('Eco-Customers-title')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-speed-dial-action icon="add" (onClick)="openCustomerDialog()">
                </dx-speed-dial-action>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <div fxLayout="row" class="responsive-paddings">
                <ww-grid [height]="'100%'" class="grid-group-by-style" #customerGrid [id]="'customerGrid'"
                    (onInitialized)="onInitialized($event)" [columnsHeader]='columnsHeader' [gridData]='customers'
                    [enableExport]="true" [allowUpdating]="false" [enableColumnChooser]="false"
                    [allowExportSelectedData]="false" [rowAlternationEnabled]="false" [allowColumnReordering]="false"
                    [allowDeleting]="false" [enableLoadPanel]="false" [allowAdding]="false" [pageable]="false"
                    (onExporting)="onExporting($event)">
                </ww-grid>
            </div>
            <dx-popup #addCustomerPopup class="popup-window-style" title="{{dataService.res('Eco-Customers-title')}}"
                [width]="'55%'" [height]="'90%'" [showCloseButton]="'true'" (onHidden)="onHidden($event)">
                <dx-scroll-view>
                    <ecoBase-create-customer #createForm (closePopup)="closePopup()"
                        (createCustomerOutput)="createCustomerOutput($event)"></ecoBase-create-customer>
                </dx-scroll-view>
            </dx-popup>
        </div>
    </div>
</div>
<dx-popup #deleteCustomerPopup [width]="'25%'" [showTitle]="false" fxLayout="column" fxLayoutAlign="center center"
    [height]="'auto'" [showCloseButton]="true">
    <div class="delete-popup">
        <div *ngIf="isDeleteWarning" class="warning-msg"><i class="dx-icon-warning dx-icon-custom-style"></i>
            {{dataService.res(warningMsg)}}</div>
        <div>{{ dataService.res('Eco-Experiments-Delete-Row') }}</div>
        <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
            <dx-button *ngIf="!isDeleteWarning" class="btn-create" [text]="dataService.res('Eco-Delete')"
                (onClick)="deleteCustomer()">
            </dx-button>
            <dx-button class="btn-cancel" [text]="dataService.res('Eco-Cancel')" (onClick)="onCloseDeletePopup()">
            </dx-button>
        </dxi-item>
    </div>
</dx-popup>

<ecoBase-eco-loader *ngIf="showLoader"></ecoBase-eco-loader>