import { Person, PhoneNumber } from "@geods/base";

interface PersonPersistModel {
    PersonnelNumber: string,
    FirstName: string,
    LastName: string,
    PrivatePhone: string,
    Function: string,
    EMail: string,
    Login: string,
    Title: string,
    ParentId: string
}

export class PersonViewModel {
    protected person: Person;
    get id(): string { return this.person.Id; }
    set id(id: string) { this.person.Id = id; }
    get parentId(): string { return this.person.ParentId; }
    set parentId(parentId: string) { this.person.ParentId = parentId; }
    get fullName(): string { return this.person.FirstName + ', ' + this.person.LastName; }
    get firstName(): string { return this.person.FirstName; }
    set firstName(firstName: string) { this.person.FirstName = firstName; }
    get lastName(): string { return this.person.LastName; }
    set lastName(lastName: string) { this.person.LastName = lastName; }
    get eMail(): string { return this.person.EMail; }
    set eMail(eMail: string) { this.person.EMail = eMail; }
    get privatePhone(): string { return this.person.PrivatePhone; }
    set privatePhone(privatePhone: string) { this.person.PrivatePhone = privatePhone; }
    get function(): string { return this.person.Function; }
    set function(functionPerson: string) { this.person.Function = functionPerson; }
    get address(): string { return this.person.Address; }
    set address(address: string) { this.person.Address = address; }
    get birthday(): Date { return this.person.Birthday; }
    set birthday(birthday: Date) { this.person.Birthday = birthday; }
    get city(): string { return this.person.City; }
    set city(city: string) { this.person.City = city; }
    get country(): string { return this.person.Country; }
    set country(country: string) { this.person.Country = country; }
    get department(): string { return this.person.Department; }
    set department(department: string) { this.person.Department = department; }
    get login(): string { return this.person.Login; }
    set login(login: string) { this.person.Login = login; }
    get personnelNumber(): string { return this.person.PersonnelNumber; }
    set personnelNumber(personnelNumber: string) { this.person.PersonnelNumber = personnelNumber; }
    get title(): string { return this.person.Title; }
    set title(title: string) { this.person.Title = title; }
    get creationDate(): Date { return this.person.SysDateInsert; }
    set creationDate(creationDate: Date) { this.person.SysDateInsert = creationDate; }
    get phoneNumber(): PhoneNumber {
        if (this.person.PhoneNumbers && this.person.PhoneNumbers[0]) { return this.person.PhoneNumbers[0]; }
        else {
            return new PhoneNumber(null)
        }
    }
    set phoneNumber(phoneNumber: PhoneNumber) {
        if (this.person.PhoneNumbers && this.person.PhoneNumbers[0])
            this.person.PhoneNumbers[0] = phoneNumber;
    }

    get employeePhone(): string {
        if (this.person.PhoneNumbers && this.person.PhoneNumbers[0].CountryCode && this.person.PhoneNumbers[0].Number) {
            return `+${this.person.PhoneNumbers[0].CountryCode} ${this.person.PhoneNumbers[0].Number}`;
        }
    }
    set employeePhone(employeePhone: string) {
        if (this.person.PhoneNumbers && this.person.PhoneNumbers[0]) {
            this.person.PhoneNumbers[0].Number = employeePhone;
        }
    }
    get profilePicture(): string {
        if (this.person.ProfilePicture) {
            return 'data:image/png;base64,' + this.person.ProfilePicture[0].Object;
        }
    }
    get profilePictureId(): string {
        if (this.person.ProfilePicture)
            return this.person.ProfilePicture[0].Id;
    }
    constructor(person) {
        this.person = person;
    }

    private formatPhoneNumber(phone): string {
        if (phone && phone.CountryCode) {
            return '+' + [phone.CountryCode,
            phone.LocalAreaCode,
            phone.Number,
            phone.Mediation].filter((number) => (
                number != null &&
                number != undefined &&
                number.length > 0
            )).join(' ')
        }
    }

    preparePersonReq(): PersonPersistModel {
        const req: PersonPersistModel = {
            PersonnelNumber: this.personnelNumber,
            FirstName: this.firstName,
            LastName: this.lastName,
            PrivatePhone: this.privatePhone,
            Function: this.function,
            EMail: this.eMail,
            Login: this.login,
            Title: this.title,
            ParentId: this.parentId
        }
        return req;
    }
}
