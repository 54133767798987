import { Address, GeoDsDocument, GeoDsMeta } from "@geods/base";
import { EcoLibrary } from "./eco-library.model";

export class Technology extends GeoDsMeta {
    Name: string;
    Description: string;
    Image: GeoDsDocument;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}