import { Injectable } from '@angular/core';
import { GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import CustomStore from 'devextreme/data/custom_store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DataService, ObjectKeys } from '../../../services/data.service';

export interface PickListItem {
    Id: string,
    Description: string,
    Value: string
}

@Injectable({
    providedIn: 'root'
})
export class DropBoxService {
    private designationToAdd = '';
    constructor(private persistenceService: GeoDsPersistenceService, public dataService: DataService) { }

    public returnCustomStore(pickListName: string, itemsArray: PickListItem[] = []): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            key: 'Value',
            load: () => {
                return this.dataService.getPickListItemsByPickListName(pickListName).pipe(switchMap((data) => {
                    if (Array.isArray(data) && data.length > 0) {
                        return of(data.sort((a, b) => (a.Description > b.Description) ? 1 : ((b.Description > a.Description) ? -1 : 0)))
                    } else {
                        return of([])
                    }
                })).toPromise();
            },
            onInserted: (values) => {
                let newItem = { Id: values.Id, Description: this.designationToAdd, Value: this.getItemMaxValue(itemsArray) };
                this.designationToAdd = '';
                if (Array.isArray(itemsArray) && itemsArray.length > 0) {
                    itemsArray.unshift(newItem);
                } else {
                    itemsArray = [newItem]
                }
            },
            insert: (values) => {
                this.designationToAdd = values.Description
                return (this.persistItem(values.Description, itemsArray, pickListName, null).toPromise());
            },
            update: (key, values) => {
                return (this.persistItem(values.Description, itemsArray, pickListName, key).toPromise());
            },
            onUpdated: (key, values) => {
                const arrKeys = itemsArray.map(control => control.Value);
                const lastIndexOfItem = arrKeys.lastIndexOf(key);
                itemsArray[lastIndexOfItem].Description = values.Description;
            },
            remove: (key) => {
                return this.deleteControlItem(key, itemsArray).toPromise();
            }
        });
    }

    private persistItem(description: string, array: PickListItem[], pickListName: string, controlItemValue: string): Observable<any> {
        if (!(Array.isArray(array) && array.length > 0)) {
            array = [];
        }
        return this.dataService.getPickListByName(pickListName).pipe(switchMap((pickList) => {
            const listId = pickList[0].Id;

            const controlQuery = controlItemValue ? {
                Description: description
            } : {
                ParentId: listId,
                Value: this.getItemMaxValue(array),
                Description: description
            };

            const controlPersistQuery: TargetObjectData = new TargetObjectData();
            controlPersistQuery.ObjectKey = new ObjectKey();

            if (controlItemValue) {
                const arrKeys = array.map(control => control.Value);
                const lastIndexOfItem = arrKeys.lastIndexOf(controlItemValue);

                controlPersistQuery.Mode = PersistMode.Update;
                controlPersistQuery.ObjectKey.Id = array[lastIndexOfItem].Id;
            } else {
                controlPersistQuery.Mode = PersistMode.Insert;
            }

            controlPersistQuery.ObjectKey.ObjectType = ObjectKeys.PICKLISTITEM;
            const controlColumns: TargetColumnValue[] = this.dataService.geoDsPersistBody(controlQuery);
            controlPersistQuery.TargetColumns = controlColumns;
            const query: PersistObjectModel = new PersistObjectModel();
            query.Object = controlPersistQuery;
            return this.persistenceService.executePersistObjectQuery(query);
        }))
    }

    private deleteControlItem(ItemValue: string, array: PickListItem[]): Observable<any> {
        if (Array.isArray(array) && array.length > 0) {
            const arrKeys = array.map(control => control.Value);
            const lastIndexOfItem = arrKeys.lastIndexOf(ItemValue);

            if (lastIndexOfItem === -1) {
                return of(null)
            } else {
                return this.dataService
                    .deleteObject(ObjectKeys.PICKLISTITEM, array[lastIndexOfItem].Id)
            }
        } else {
            return of(null)
        }
    }

    private getItemMaxValue(array: PickListItem[]): string {
        if (Array.isArray(array) && array.length > 0) {
            let maxValue = array.filter((item) => Number.isInteger(parseInt(item.Value))).reduce((acc, item) => {
                return (acc = acc > parseInt(item.Value) ? acc : parseInt(item.Value));
            }, 0);

            let res = isNaN(maxValue + 1) ? 0 : maxValue + 1;
            return res.toString();
        } else {
            return '0'
        }
    }


}