<form action="post" (submit)="createMachine($event)">
    <dx-form #form id="form">
        <dxi-item>
            <dx-text-box name="maschinenname" labelMode="floating" [(value)]="machine.trialMachineName"
                [label]="dataService.res('Eco-Machines-Maschinenname')+'*'">
            </dx-text-box>
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4">
            <dxi-item [colSpan]="2" class="floatingLabel">
                <dx-text-box mask="0000" [(value)]="machine.year" [stylingMode]="'outlined'" [width]="'100%'"
                    [labelMode]="'floating'" [label]="dataService.res('Eco-Machines-Baujahr')">
                </dx-text-box>
            </dxi-item>
            <dxi-item [colSpan]="2" class="floatingLabel">
                <eco-drop-box [(gridValue)]="machine.machineType" [gridDataSource]="typesGridDataSource"
                    [label]="'Eco-Materials-Machine-Type'"></eco-drop-box>
            </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="4">
            <dxi-item [colSpan]="2" class="floatingLabel">
                <eco-drop-box [(gridValue)]="machine.manufacturer" [gridDataSource]="manufacturersGridDataSource"
                    [label]="'Eco-Materials-Manufacturer'"></eco-drop-box>
            </dxi-item>
            <dxi-item [colSpan]="2" class="floatingLabel">
                <eco-drop-box [(gridValue)]="machine.control" [gridDataSource]="controlGridDataSource"
                    [label]="'Eco-Machines-Control'"></eco-drop-box>
            </dxi-item>
        </dxi-item>

        <dxi-item class="floatingLabel">
            <dx-text-area [height]="'150px'" name="beschreibung" labelMode="floating" [(value)]="machine.description"
                [label]="dataService.res('Eco-Machines-Beschreibung')">
            </dx-text-area>
        </dxi-item>
        <dxi-item itemType="group">
            <div *dxTemplate="let data of 'dxi-item'">
                <app-eco-uploader #pictureUploader [acceptedFileType]="'image/*'" dropZoneWidth="100%"
                    [title]="'Eco-Upload'" [object]="object" [fileDesignation]="'imgMachine'"
                    [imgSource]="machine.image">
                </app-eco-uploader>
            </div>
        </dxi-item>
        <dxi-item [cssClass]="'div-buttons'" itemType="group">
            <dxi-item [cssClass]="'btn-create'" itemType="button" [(buttonOptions)]="createButtonOptions">
            </dxi-item>
            <dxi-item [cssClass]="'btn-cancel'" itemType="button" [buttonOptions]="CancelButtonOptions">
            </dxi-item>
        </dxi-item>
    </dx-form>
</form>