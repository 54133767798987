import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Machine } from '@ecoroll/models';
import { ToastType } from '@wissenswerft/ww-library';
import { DxPopupComponent } from 'devextreme-angular';
import { forkJoin, Subscription } from 'rxjs';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { DropBoxService, PickListItem } from '../shared/eco-drop-box/eco-drop-box.service';
import { MachinePickList, MachineViewModel } from '../view-models/machine.view-model';
import { CreateMachineComponent } from './create-machine/create-machine.component';

export enum machinesPickLists {
  ECMACHINETYPES = "ecMachineTypes",
  ECMACHINECONTROLS = "ecMachineControls"
}
@Component({
  selector: 'ecoBase-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.scss']
})
export class MachinesComponent implements OnInit, OnDestroy {
  @ViewChild("addMachinePopup") addMachinePopup: DxPopupComponent;
  @ViewChild("deleteMachinePopup") deleteMachinePopup: DxPopupComponent;
  @ViewChild('createForm') createForm: CreateMachineComponent;
  public machines: MachineViewModel[] = [];
  public selectedMachineId = "";
  public showLoader = true;
  private subscriptions: Subscription[] = [];
  public manufacturers = [];
  public types: PickListItem[] = [];
  public controls: PickListItem[] = [];

  constructor(
    public dataService: DataService,
    private appService: AppService,
    private dropBoxService: DropBoxService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataService.readNestedObjectsWithColumns<Machine>(
        {
          modelName: ObjectKeys.ECOMACHINE,
          specialColumns: [
            { name: "TypeName", opath: "ref:Type" },
            { name: "ControlName", opath: "ref:Control" },
            { name: "ManufacturerName", opath: "ref:ManufacturerCompany" }
          ],
          ObjectQueries: [
            {
              modelName: ObjectKeys.ECOLIBRARY, Opath: "EcoLibrary", Name: "EcoLibrary"
            },
            {
              modelName: ObjectKeys.Document, Opath: "Documents[Designation='imgMachine']", Name: "Image",
              columns: ['Id', 'Designation'],
              specialColumns: [{ name: "Object", opath: "Object", format: 'Base64Image[300x200]' }]
            }
          ]
        }
      ).subscribe((machines) => {
        if (machines.length > 0) {
          machines.forEach((machine, index) => {
            this.machines.push(new MachineViewModel(machine));

            if (index + 1 === machines.length) {
              this.showLoader = false;
            }
          }, error => {
            console.error(error);
            this.showLoader = false;
          });
        } else {
          this.showLoader = false;
        }
      }, (error) => {
        this.showLoader = false;
        this.appService.callNotification({
          message: this.dataService.res('Eco-Error'),
          type: ToastType.ERROR
        });
      })
    );
    //TODO change loadListDataSource with dataService readPickList 
    this.subscriptions.push(forkJoin([
      this.dataService.getPickListItemsByPickListName(MachinePickList.TYPES),
      this.dataService.getPickListItemsByPickListName(MachinePickList.MANUFACTURERS),
      this.dataService.getPickListItemsByPickListName(MachinePickList.CONTROLS)
    ]).subscribe(([types, manufacturers, controls]) => {
      this.manufacturers = manufacturers;
      this.types = types;
      this.controls = controls;

      this.createForm.typesGridDataSource = this.dropBoxService.returnCustomStore(MachinePickList.TYPES, this.types);

      this.createForm.manufacturersGridDataSource = this.dropBoxService.returnCustomStore(MachinePickList.MANUFACTURERS, this.manufacturers);

      this.createForm.controlGridDataSource = this.dropBoxService.returnCustomStore(MachinePickList.CONTROLS, this.controls);
    }));

  }

  public openMachineDialog(data?): void {
    if (data?.data) {
      const machineModel = { ... { ...data?.data }["machine"] };
      this.createForm.machine = new MachineViewModel(machineModel);
      this.createForm.update = true;
    } else {
      const machine = new MachineViewModel(new Machine({}));
      this.createForm.machine = machine;
      this.createForm.update = false;
      this.createForm.form.instance.resetValues();
    }
    this.addMachinePopup.instance.show();
  }

  public createMachineOutput(event: { isUpdated: boolean, Id: string }): void {
    this.showLoader = true;
    this.dataService.readNestedObjectsWithColumns<Machine>(
      {
        modelName: ObjectKeys.ECOMACHINE,
        condition: "Id='" + event.Id + "'",
        specialColumns: [
          { name: "TypeName", opath: "ref:Type" },
          { name: "ControlName", opath: "ref:Control" },
          { name: "ManufacturerName", opath: "ref:ManufacturerCompany" }
        ],
        ObjectQueries: [
          { modelName: ObjectKeys.ECOLIBRARY, Opath: "EcoLibrary", Name: "EcoLibrary" },
          {
            modelName: ObjectKeys.Document, Opath: "Documents[Designation='imgMachine']", Name: "Image",
            columns: ['Id', 'Designation'],
            specialColumns: [{ name: "Object", opath: "Object" }]
          }
        ]
      }
    ).subscribe((persistedMachineData) => {
      const persistedMachine = new MachineViewModel(persistedMachineData[0]);
      if (event.isUpdated) {
        let selectedIndex = this.machines.findIndex((machine) => machine.id === event.Id);
        this.machines[selectedIndex] = persistedMachine;
        this.showLoader = false;
      } else {
        this.machines.unshift(persistedMachine);
        this.showLoader = false;
      }
    })
  }

  public openDeleteMachineDialog(event): void {
    this.selectedMachineId = event.data.id;
    this.deleteMachinePopup.instance.show();
  }

  public deleteMachine(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.ECOMACHINE, this.selectedMachineId)
        .subscribe(
          (deletedMachine) => {
            if (deletedMachine?.Id) {
              this.machines = this.machines.filter(
                (machine) => machine.id != deletedMachine?.Id
              );
              this.appService.callNotification({
                message: this.dataService.res('Eco-Success'),
                type: ToastType.SUCCESS
              });
            }
          },
          (error) => {
            this.appService.callNotification({
              message: this.dataService.res('Eco-Error'),
              type: ToastType.ERROR
            });
          }, () => {
            this.onCloseDeletePopup();
          }
        )
    );
  }

  public closePopup(): void {
    this.addMachinePopup.instance.hide();
  }

  public onCloseDeletePopup(): void {
    this.deleteMachinePopup.instance.hide();
  }

  public emptyData(): void {
    this.createForm.form.instance.resetValues();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
