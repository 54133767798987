import { GeoDsMeta } from "@geods/base";

export class Wavy extends GeoDsMeta {
    MeanWsmPeriodLength: number;
    VerticalRippleDifference: number;
    MeanRippleValue: number;
    AritmeticRipple: number;
    RootMeanSquareRipple: number;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}