<div fxLayout="column" class="content-style-class" [style.filter]="showLoader == true ? 'blur(0.2rem)' : 'blur(0)'">
  <div class="content-block">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <h2 class="title-style">{{dataService.res('Eco-Measure-Devices')}}</h2>
      </div>
      <div fxLayout="column" class="add-button-style">
        <dx-speed-dial-action icon="add" (onClick)="addMeasureDeviceDialog()">
        </dx-speed-dial-action>
      </div>
    </div>
    <div class="dx-card responsive-paddings">
      <div fxLayout="row" class="responsive-paddings">
        <ww-grid #measuringDevicesGrid [height]="'100%'" class="grid-group-by-style" [columnsHeader]='columnsHeader'
          [gridData]='measuringDevices' [enableExport]="true" [allowUpdating]="false" [enableColumnChooser]="false"
          [allowExportSelectedData]="false" [rowAlternationEnabled]="false" [allowColumnReordering]="false"
          [allowDeleting]="false" [enableLoadPanel]="false" [allowAdding]="false" [pageable]="false"
          [editingMode]="editingMode" (onRowInserted)="onRowInserted($event)">
        </ww-grid>
      </div>
    </div>
  </div>
</div>

<dx-popup #deleteMeasuringDevicePopup [width]="'25%'" [showTitle]="false" fxLayout="column"
  fxLayoutAlign="center center" [height]="'auto'" [showCloseButton]="true">
  <div class="delete-popup">
    <div [ngClass]="{'warning-msg':isDeleteWarningVisible}"> <i *ngIf="isDeleteWarningVisible"
        class="dx-icon-warning dx-icon-custom-style"></i> {{warningMsg}}</div>
    <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
      <dx-button *ngIf="!isDeleteWarningVisible" class="btn-create" [text]="dataService.res('Eco-Delete')"
        (onClick)="deleteMeasureDevice()">
      </dx-button>
      <dx-button class="btn-cancel" [text]="dataService.res('Eco-Cancel')" (onClick)="onCloseDeletePopup()">
      </dx-button>
    </dxi-item>
  </div>
</dx-popup>

<ecoBase-eco-loader *ngIf="showLoader"></ecoBase-eco-loader>