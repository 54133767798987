import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Person, PhoneNumber } from '@geods/base';
import { GeoDsCoreDataService } from '@wissenswerft/core/data';
import { ToastType } from '@wissenswerft/ww-library';
import { DxDataGridComponent, DxPopupComponent } from 'devextreme-angular';
import { forkJoin, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { PersonViewModel } from '../view-models/person.view-model';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { MaterialPickList } from '../view-models/material.view-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'ecoBase-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit, OnDestroy {
  @ViewChild('employeeGrid') employeeGrid: DxDataGridComponent;
  @ViewChild('addEmployeePopup') addEmployeePopup: DxPopupComponent;
  @ViewChild('createForm') createForm: CreateEmployeeComponent;
  @ViewChild('deletePersonPopup') deletePersonPopup: DxPopupComponent;
  public selectedPersonId: string;
  public showLoader = true;
  public employees: PersonViewModel[] = [];
  private subscriptions: Subscription[] = [];
  public functions = [];
  public isDeleteWarning = false;
  public warningMsg = '';
  constructor(
    public dataService: DataService,
    private appService: AppService,
    private coreDataService: GeoDsCoreDataService
  ) { }

  ngOnInit(): void {
    //TODO change loadListDataSource with dataService readPickList
    const filterEcorollCompany = "Name == 'ECOROLL'";
    const addressColumns = [this.coreDataService.createQueryColumn('Id', 'Id'), this.coreDataService.createQueryColumn('Name', 'Name')];
    this.subscriptions.push(
      forkJoin([
        this.dataService.getPickListItemsByPickListName(MaterialPickList.FUNCTION),
        this.dataService.readObjects(ObjectKeys.ADDRESS, addressColumns, filterEcorollCompany)
      ]).pipe(switchMap(([functions, adresses]) => {
        this.functions = functions;
        const filterOutEcorollEmployess = "ParentId='" + adresses[0].Id + "'";
        return this.dataService.readNestedObjectsWithColumns<Person>(
          {
            modelName: ObjectKeys.PERSON,
            condition: filterOutEcorollEmployess,
            ObjectQueries: [
              {
                modelName: ObjectKeys.PERSONPHONENUMBER, Opath: "PersonPhoneNumbers", Name: "PhoneNumbers"
              },
              {
                modelName: ObjectKeys.Document, Opath: "Documents[Designation='profilePicture']", Name: "ProfilePicture",
                columns: ['Id', 'Designation'],
                specialColumns: [{ name: "Object", opath: "Object", format: 'Base64Image[100x100]' }]
              }
            ]
          }
        )
      }))
        .subscribe(
          employees => {
            if (employees.length > 0) {
              employees?.forEach((employe, index) => {
                this.employees.push(new PersonViewModel(employe));
                if (index + 1 === employees.length) {
                  this.showLoader = false;
                }
              })
            } else {
              this.showLoader = false;
            }
          }, (error) => {
            this.showLoader = false;
            this.appService.callNotification({
              message: this.dataService.res('Eco-Error'),
              type: ToastType.ERROR
            });
          }
        )
    )
  }

  public openEmployeeDialog(data?: PersonViewModel): void {
    if (data) {
      const employeeModel: Person = this.dataService.deepClone({ ... { ...data } }["person"])

      if (!employeeModel.PhoneNumbers) {
        employeeModel.PhoneNumbers = [new PhoneNumber(null)];
      }

      if (employeeModel.PhoneNumbers && !employeeModel.PhoneNumbers[0].Designation) {
        employeeModel.PhoneNumbers[0].Designation = CountryISO.Germany;
      }

      this.createForm.phoneForm = new FormGroup({
        phone: new FormControl(data.phoneNumber.Number, [Validators.required]),
      });

      this.createForm.employee = new PersonViewModel(employeeModel);
      this.createForm.update = true;
    } else {

      const phoneNumber = new PhoneNumber(null)
      const employee = new PersonViewModel(new Person({ PhoneNumbers: { ...phoneNumber, Designation: CountryISO.Germany } }));
      this.createForm.employee = employee;
      this.createForm.phoneForm = new FormGroup({
        phone: new FormControl(null, [Validators.required]),
      });
      this.createForm.update = false;
      this.createForm.form.instance.resetValues();
    }
    this.createForm.isPhoneReady = true;
    this.addEmployeePopup.instance.show();
  }

  public closePopup(): void {
    this.createForm.isPhoneReady = false;
    this.addEmployeePopup.instance.hide();
  }

  public createEmploye(event: { isUpdated: boolean, Id: string }): void {
    this.showLoader = true;
    forkJoin([
      this.dataService.getPickListItemsByPickListName(MaterialPickList.FUNCTION),
      this.dataService.readNestedObjectsWithColumns<Person>(
        {
          modelName: ObjectKeys.PERSON,
          condition: "Id='" + event.Id + "'",
          ObjectQueries: [
            {
              modelName: ObjectKeys.PERSONPHONENUMBER, Opath: "PersonPhoneNumbers", Name: "PhoneNumbers"
            },
            {
              modelName: ObjectKeys.Document, Opath: "Documents[Designation='profilePicture']", Name: "ProfilePicture",
              specialColumns: [{ name: "Object", opath: "Object" }]
            }
          ]
        }
      )])
      .subscribe((data) => {
        this.functions = data[0];
        const persistedEmployeeData = data[1];
        const persistedEmployee = new PersonViewModel(persistedEmployeeData[0]);
        if (event.isUpdated) {
          let selectedIndex = this.employees.findIndex((employee) => employee.id === event.Id);
          this.employees[selectedIndex] = persistedEmployee;
          const employeesFunctions = [];
          this.employees.forEach(empl => {
            if (!employeesFunctions.includes(empl.function)) {
              employeesFunctions.push(empl.function);
            }
          });
          const filterFunctions = [];
          employeesFunctions.forEach(employeeFunction => {
            const index = this.functions.findIndex(fonction => fonction.Value == employeeFunction);
            filterFunctions.push(this.functions[index]);
          });
          this.functions = filterFunctions;
          this.showLoader = false;
        } else {
          this.employees.unshift(persistedEmployee);
          const employeesFunctions = [];
          this.employees.forEach(empl => {
            if (!employeesFunctions.includes(empl.function)) {
              employeesFunctions.push(empl.function);
            }
          });
          const filterFunctions = [];
          employeesFunctions.forEach(employeeFunction => {
            const index = this.functions.findIndex(fonction => fonction.Value == employeeFunction);
            filterFunctions.push(this.functions[index]);
          });
          this.functions = filterFunctions;
          this.showLoader = false;
        }
      })
  }

  public openDeletePersonDialog(person: PersonViewModel): void {
    this.selectedPersonId = person.id;
    const columns = [this.coreDataService.createQueryColumn('Id', 'Id')]
    const testOpath = `IdRefPersonAppTechnician='${this.selectedPersonId}' OR IdRefPerson='${this.selectedPersonId}'`;
    const reportOpath = `IdRefProjectManager='${this.selectedPersonId}' OR IdRefPerson='${this.selectedPersonId}' OR IdRefPersonAppTechnician='${this.selectedPersonId}'`;
    this.subscriptions.push(
      forkJoin([
        this.dataService.readObjects(ObjectKeys.ECOTEST, columns, testOpath),
        this.dataService.readObjects(ObjectKeys.ECOTESTREPORT, columns, reportOpath)
      ]).subscribe(([tests, reports]) => {
        if (tests?.length > 0) {
          this.isDeleteWarning = true;
          this.warningMsg = "Eco-Delete-Msg-Warning-Tests";
        }
        if (reports?.length > 0) {
          this.isDeleteWarning = true;
          this.warningMsg = "Eco-Delete-Msg-Warning-Reports";
        }
        this.deletePersonPopup.instance.show();
      }, (error) => {
        this.deletePersonPopup.instance.show();
      })
    )
  }

  public onCloseDeletePopup(): void {
    this.deletePersonPopup.instance.hide();
    this.isDeleteWarning = false;
  }

  public deletePerson(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.PERSON, this.selectedPersonId)
        .subscribe(
          (deletedPerson) => {
            if (deletedPerson?.Id) {
              this.employees = this.employees.filter(
                (employe) => employe.id != deletedPerson?.Id
              );
              this.appService.callNotification({
                message: this.dataService.res('Eco-Success'),
                type: ToastType.SUCCESS
              });
            }
          },
          (error) => {
            this.appService.callNotification({
              message: this.dataService.res('Eco-Error'),
              type: ToastType.ERROR
            });
          }, () => {
            this.onCloseDeletePopup();
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
