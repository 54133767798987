<dx-drop-down-box class="drop-down-style input-full-width" [(value)]="gridValue" [(opened)]="isGridBoxOpened"
  valueExpr="Value" [deferRendering]="false" displayExpr="Description" [label]="dataService.res(label)"
  [labelMode]="'floating'" [placeholder]="dataService.res('Eco-Select-Value')" [showClearButton]="true"
  [isValid]="isValid" [dataSource]="gridDataSource" (onOptionChanged)="onGridBoxOptionChanged($event)">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid [dataSource]="gridDataSource" [columns]="columnsHeader" [hoverStateEnabled]="true"
      [(selectedRowKeys)]="gridValue" (onSelectionChanged)="onSelectionChanged($event)" height="100%">
      <dxo-selection mode="single"></dxo-selection>
      <dxo-editing [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" mode="row"></dxo-editing>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-paging [enabled]="false" [pageSize]="10"></dxo-paging>
    </dx-data-grid>
  </div>
</dx-drop-down-box>