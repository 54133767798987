import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IToast } from '@wissenswerft/ww-library';

export interface ColumnLookup {
  allowClearing?: boolean;
  dataSource?: any[];
  displayExpr?: string | ((data: any) => string);
  valueExpr?: string;
  calculateCellValue?: ((rowData: any) => any);
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private notificationSubject: Subject<IToast> = new Subject();
  public notification$: Observable<IToast> = this.notificationSubject.asObservable();
  private testSupportDataSubject: Subject<any[]> = new Subject();
  public readonly CONFIG_PROPERTIES = ['Id', 'ParentId', 'SysTimeInsert', 'SysTimeUpdate', 'SysUserNameInsert', 'SysDateUpdate', 'SysUserNameUpdate', 'SeqNr', 'ObjectType'];
  public testSupportData$: Observable<any[]> = this.testSupportDataSubject.asObservable();

  constructor() { }

  public callNotification(notification: IToast): void {
    this.notificationSubject.next(notification);
  }

  public calltestSupportData(data): void {
    this.testSupportDataSubject.next(data);
  }

  public getDataType(dataType) {
    switch (dataType) {
      case 'String':
        return 'string';
      case 'Integer32':
        return 'number';
      case 'Date':
        return 'date';
      default:
        return 'string';
    }
  }
}
