import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BACKEND_SYSTEM, CoreConfigService, CoreConfigurationModule } from '@wissenswerft/core/configuration';
import { environment } from '../environments/environment';
import { CommonModule } from '@angular/common';
import { CoreAuthenticationModule } from '@wissenswerft/core/authentication';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WwLibraryModule } from '@wissenswerft/ww-library';
import { MatIconModule } from '@angular/material/icon';
import { DxContextMenuModule, DxDrawerModule, DxScrollViewModule, DxSelectBoxModule, DxSwitchModule, DxToolbarModule, DxTooltipModule, DxTreeViewModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ScreenService } from './services/screen.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    WwLibraryModule,
    CoreAuthenticationModule,
    CoreConfigurationModule,
    AppRoutingModule,
    CommonModule,
    DxToolbarModule,
    DxSelectBoxModule,
    DxScrollViewModule,
    DxContextMenuModule,
    DxSwitchModule,
    DxTreeViewModule,
    DxDrawerModule,
    MatIconModule,
    DxTooltipModule,
    BrowserAnimationsModule
  ],
  providers: [
    ScreenService,
    CoreConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigurationFactory,
      deps: [CoreConfigService],
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function ConfigurationFactory(globalConfig: CoreConfigService) {
  return () => {
    return new Promise((resolve) => {
      globalConfig.loadConfiguration(environment.API_URL, BACKEND_SYSTEM.GEODS).then((config) => {
        resolve(config);
      }
      );
    });
  };
}
