import { EcoLibrary, Hardness, Material } from "@ecoroll/models";

export enum MaterialPickList {
    CONDITION = 'ecMaterialConditions',
    FUNCTION = 'Personenfunktion',
    MAINGROUP = 'ecMaterialMainGroups'
}

export class MaterialViewModel {

    private material: Material;

    get id(): string { return this.material.Id; }
    set id(id: string) { this.material.Id = id; }

    get conditionHeatTreatment(): string { return this.material.ConditionHeatTreatment; }
    set conditionHeatTreatment(data: string) { this.material.ConditionHeatTreatment = data }
    get condition(): string { return this.material.Condition; }
    set condition(data: string) {
        this.material.Condition = data[0];
    }

    get density(): number { return this.material.Density; }
    set density(data: number) { this.material.Density = data; }

    get densityUnit(): string { return this.material.DensityUnit; }
    set densityUnit(data: string) { this.material.DensityUnit = data; }

    get designationAFNOR(): string { return this.material.DesignationAFNOR; }
    set designationAFNOR(data: string) { this.material.DesignationAFNOR = data; }

    get designationAlloy(): string { return this.material.DesignationAlloy; }
    set designationAlloy(data: string) { this.material.DesignationAlloy = data; }

    get designationEurope(): string { return this.material.DesignationEurope; }
    set designationEurope(data: string) { this.material.DesignationEurope = data; }

    get designationGB(): string { return this.material.DesignationGB; }
    set designationGB(data: string) { this.material.DesignationGB = data; }

    get designationGer(): string { return this.material.DesignationGer; }
    set designationGer(data: string) { this.material.DesignationGer = data; }

    get designationGost(): string { return this.material.DesignationGost; }
    set designationGost(data: string) { this.material.DesignationGost = data; }

    get designationJIS(): string { return this.material.DesignationJIS; }
    set designationJIS(data: string) { this.material.DesignationJIS = data; }

    get designationSIS(): string { return this.material.DesignationSIS; }
    set designationSIS(data: string) { this.material.DesignationSIS = data; }

    get designationSS(): string { return this.material.DesignationSS; }
    set designationSS(data: string) { this.material.DesignationSS = data; }

    get designationUNE(): string { return this.material.DesignationUNE; }
    set designationUNE(data: string) { this.material.DesignationUNE = data; }

    get designationUNI(): string { return this.material.DesignationUNI; }
    set designationUNI(data: string) { this.material.DesignationUNI = data; }

    get designationUNS(): string { return this.material.DesignationUNS; }
    set designationUNS(data: string) { this.material.DesignationUNS = data; }

    get designationUS(): string { return this.material.DesignationUS; }
    set designationUS(data: string) { this.material.DesignationUS = data; }

    get electricalResistance(): number { return this.material.ElectricalResistance; }
    set electricalResistance(data: number) { this.material.ElectricalResistance = data; }
    get hardnessFullText(): string { return this.material.HardnessFullText; }
    set hardnessFullText(data: string) { this.material.HardnessFullText = data; }
    get hardness(): Hardness {
        return this.material.Hardness ? this.material.Hardness[0] : this.material.Hardness;
    }
    set hardness(data: Hardness) { this.material.Hardness[0] = data; }

    get mainGroup(): string { return this.material.MainGroup; }
    set mainGroup(data: string) { this.material.MainGroup = data[0]; }

    get mainGroupMaching(): string { return this.material.MainGroupMaching; }
    set mainGroupMaching(data: string) { this.material.MainGroupMaching = data; }

    get mainGroupDescription(): string { return this.material.MainGroupDescription; }
    set mainGroupDescription(data: string) { this.material.MainGroupDescription = data; }

    get materialName(): string { return this.material.MaterialName; }
    set materialName(data: string) { this.material.MaterialName = data; }

    get materialNr(): string { return this.material.MaterialNr; }
    set materialNr(data: string) { this.material.MaterialNr = data; }

    get modulusElasticity(): number { return this.material.ModulusElasticity; }
    set modulusElasticity(data: number) { this.material.ModulusElasticity = data; }

    get modulusElasticityUnit(): string { return this.material.ModulusElasticityUnit; }
    set modulusElasticityUnit(data: string) { this.material.ModulusElasticityUnit = data; }

    get specificHeatCapacity(): number { return this.material.SpecificHeatCapacity; }
    set specificHeatCapacity(data: number) { this.material.SpecificHeatCapacity = data; }

    get stretchLimit(): number { return this.material.StretchLimit; }
    set stretchLimit(data: number) { this.material.StretchLimit = data; }

    get stretchLimitUnit(): string { return this.material.StretchLimitUnit; }
    set stretchLimitUnit(data: string) { this.material.StretchLimitUnit = data; }


    get thermalConductivity(): number { return this.material.ThermalConductivity; }
    set thermalConductivity(data: number) { this.material.ThermalConductivity = data; }

    get transverseContractionNbr(): number { return this.material.TransverseContractionNbr; }
    set transverseContractionNbr(data: number) { this.material.TransverseContractionNbr = data; }

    get varietyNbr(): string { return this.material.VarietyNbr; }
    set varietyNbr(data: string) { this.material.VarietyNbr = data; }


    get yieldStrength(): string { return this.material.YieldStrength; }
    set yieldStrength(data: string) { this.material.YieldStrength = data; }

    get yieldStrengthUnit(): string { return this.material.YieldStrengthUnit; }
    set yieldStrengthUnit(data: string) { this.material.YieldStrengthUnit = data; }


    get ecoLibrary(): EcoLibrary { return this.material.EcoLibrary; }
    set ecoLibrary(data: EcoLibrary) { this.material.EcoLibrary = data; }

    get ecoLibraryId(): string { return this.material['ParentId']; }
    set ecoLibraryId(ecoLibraryId: string) { this.material['ParentId'] = ecoLibraryId; }

    constructor(data: Material) {
        this.material = data;
    }

    prepareMaterialReq(): Material {
        //this.material.Condition = this.condition[0] ? this.condition[0] : null;
        return this.material;
    }
}

export enum EcoMaterialPickListItems {
    ECMODULUSELASTICITYTOOLSUNITS = "ecModulusElasticityToolUnits",
    ECDENSITYUNITS = "ecDensityUnits",
    ECSTRETCHLIMITUNITS = "ecStretchLimitUnits",
}