<form action="post" (submit)="persistTechnology($event)">
    <dx-form #form id="form" [(formData)]="technology">
        <dxi-item class="floatingLabel" [isRequired]="true">
            <dx-text-box labelMode="floating" [label]="dataService.res('Eco-Technologies-Name')+'*'"
                [(value)]="technology.name">
            </dx-text-box>
            <dxi-validation-rule #validationRule type="required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item class="floatingLabel">
            <dx-text-area [height]="'150px'" labelMode="floating" [(value)]="technology.description"
                [label]="dataService.res('Eco-Technologies-Description')">
            </dx-text-area>
        </dxi-item>
        <dxi-item itemType="group">
            <div *dxTemplate="let data of 'dxi-item'">
                <app-eco-uploader #imageUploader [acceptedFileType]="'image/*'" dropZoneWidth="100%"
                    [title]="'Eco-Technologies-Image'" [object]="object" [fileDesignation]="'image'"
                    [imgSource]="technology.image">
                </app-eco-uploader>
            </div>
        </dxi-item>

        <!-- <dxi-item class="floatingLabel" [isRequired]="false">
            <dxi-item itemType="group" [cssClass]="returnDxItemCssClass('title-bloc')">
                <p class="title-bloc">{{ dataService.res('Eco-Technologies-Description') }}</p>
            </dxi-item>
            <dx-html-editor height="250px" [(value)]="technology.description">
                <dxo-toolbar [multiline]="true">
                    <dxi-item name="undo"></dxi-item>
                    <dxi-item name="redo"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="size"
                        [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
                    <dxi-item name="font" [acceptedValues]="[
                              'Arial',
                              'Courier New',
                              'Georgia',
                              'Impact',
                              'Lucida Console',
                              'Tahoma',
                              'Times New Roman',
                              'Verdana'
                            ]"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="bold"></dxi-item>
                    <dxi-item name="italic"></dxi-item>
                    <dxi-item name="strike"></dxi-item>
                    <dxi-item name="underline"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="alignLeft"></dxi-item>
                    <dxi-item name="alignCenter"></dxi-item>
                    <dxi-item name="alignRight"></dxi-item>
                    <dxi-item name="alignJustify"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="orderedList"></dxi-item>
                    <dxi-item name="bulletList"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="color"></dxi-item>
                    <dxi-item name="background"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="link"></dxi-item>
                    <dxi-item name="image"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="clear"></dxi-item>
                    <dxi-item name="codeBlock"></dxi-item>
                    <dxi-item name="blockquote"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="insertTable"></dxi-item>
                    <dxi-item name="deleteTable"></dxi-item>
                    <dxi-item name="insertRowAbove"></dxi-item>
                    <dxi-item name="insertRowBelow"></dxi-item>
                    <dxi-item name="deleteRow"></dxi-item>
                    <dxi-item name="insertColumnLeft"></dxi-item>
                    <dxi-item name="insertColumnRight"></dxi-item>
                    <dxi-item name="deleteColumn"></dxi-item>
                </dxo-toolbar>
                <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
                <dxo-image-upload [tabs]="['file', 'url']" fileUploadMode="base64">
                </dxo-image-upload>
            </dx-html-editor>
            <dx-text-box name="technologyDescription" labelMode="floating"
                [label]="dataService.res('Eco-Technologies-Description')" [(value)]="technology.description">
            </dx-text-box>
        </dxi-item> -->
        <dxi-item [cssClass]="returnDxItemCssClass('div-buttons')" itemType="group">
            <dxi-item [cssClass]="returnDxItemCssClass('btn-create')" itemType="button"
                [(buttonOptions)]="createButtonOptions">
            </dxi-item>
            <dxi-item [cssClass]="returnDxItemCssClass('btn-cancel')" itemType="button"
                [buttonOptions]="CancelButtonOptions">
            </dxi-item>
        </dxi-item>
    </dx-form>
</form>