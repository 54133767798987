<div class="image-upload-container">
  <div class="header-container" [ngStyle]="{ 'width': headerWidth }">
    <div class="upload-header" [ngStyle]="{
        'font-size': titleFontSize,
        'color': titleColor,
        'font-weight': titleFontWeight
      }">
      {{ dataService.res(title) }}
    </div>
    <img *ngIf="!isImageDeleted && imgSource" src="assets/images/delete.svg" class="delete-btn"
      (click)="deleteDocument(true)" />
    <img *ngIf="!isImageDeleted && imgSource && documentKind === docType && document" src="assets/images/Download.svg"
      class="delete-btn" (click)="downloadFile()" />
  </div>

  <div [id]="'dropzone-external'+title" [ngStyle]="{ 'width': dropZoneWidth ,
  'height': dropZoneHeight }" class="dropzone-external flex-box"
    [ngClass]="isDropZoneActive ? ['dropzone-active'] : ['']">
    <img id="dropzone-image" [src]="imgSource" *ngIf="imgSource && !isImageDeleted && documentKind === imageType"
      alt="" />

    <div id="dropzone-image" *ngIf="imgSource && !isImageDeleted && documentKind === docType" alt=""> {{fileName}}
    </div>
    <img *ngIf="(!imgSource && textVisible) || isImageDeleted" src="assets/images/upload-file.svg"
      class="upload-icon" />
    <div id="dropzone-text" class="flex-box" *ngIf="(!imgSource && textVisible) || isImageDeleted">
      <span>{{ dataService.res('Eco-Upload-Drag') }}</span>
      <span>{{ dataService.res('Eco-Upload-Click') }}</span>
    </div>
    <dx-progress-bar #uploadProgress [min]="0" [max]="100" width="30%" [showStatus]="false" [visible]="progressVisible"
      [value]="progressValue"></dx-progress-bar>
  </div>

  <dx-file-uploader #fileUpload selectButtonText="{{ dataService.res('Eco-Upload') }}" [accept]="acceptedFileType"
    [dialogTrigger]="'#dropzone-external'+title" [dropZone]="'#dropzone-external'+title" [multiple]="false"
    [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']" uploadMode="useButtons" [visible]="false"
    (onValueChanged)="onDocumentChanged($event)" (onUploadStarted)="onUploadStarted($event)"
    (onDropZoneEnter)="onDropZoneEnter($event)" (onDropZoneLeave)="onDropZoneLeave($event)"
    (onProgress)="onProgress($event)" (onUploaded)="onUploaded()">
  </dx-file-uploader>
</div>