import { EcoLibrary, Machine } from "@ecoroll/models";
interface MachinePersistModel {
    Name: string;
    Type: string;
    ConstructionYear: number;
    Control: string;
    ManufacturerCompany: string,
    Description: string;
    ParentId: string;
    IdRefAddress: string;
}

export enum MachinePickList {
    TYPES = 'ecMachineTypes',
    MANUFACTURERS = 'ecMachineManufacturers',
    CONTROLS = 'ecMachineControls'
}
export class MachineViewModel {
    protected machine: Machine;
    get id(): string { return this.machine.Id; }
    set id(id: string) { this.machine.Id = id; }
    get trialMachineName(): string { return this.machine.Name; }
    set trialMachineName(trialMachineName: string) { this.machine.Name = trialMachineName; }
    get designation(): string { return this.machine.Designation; }
    set designation(designation: string) { this.machine.Designation = designation; }

    get description(): string { return this.machine.Description; }
    set description(machineDescription: string) { this.machine.Description = machineDescription; }
    get constructionYear(): number { return this.machine.ConstructionYear; }
    set constructionYear(constructionYear: number) { this.machine.ConstructionYear = constructionYear; }
    get year(): string {
        if (this.machine.ConstructionYear) {
            return this.machine.ConstructionYear.toString();
        }
    }
    set year(constructionYear: string) { this.machine.ConstructionYear = parseInt(constructionYear); }
    get control(): string { return this.machine.Control; }
    set control(control: string) { this.machine.Control = control[0]; }
    get controlName(): string { return this.machine["ControlName"]; }
    set controlName(controlName: string) { this.machine["ControlName"] = controlName; }
    get machineType(): string { return this.machine.Type; }
    set machineType(machineType: string) { this.machine.Type = machineType[0]; }
    get machineTypeName(): string { return this.machine["TypeName"]; }
    set machineTypeName(machineTypeName: string) { this.machine["TypeName"] = machineTypeName; }
    get manufacturer(): string { return this.machine.ManufacturerCompany; }
    set manufacturer(manufacturer: string) { this.machine.ManufacturerCompany = manufacturer[0]; }
    get manufacturerName(): string { return this.machine["ManufacturerName"]; }
    set manufacturerName(manufacturerName: string) { this.machine["ManufacturerName"] = manufacturerName; }
    get ecoLibrary(): EcoLibrary { return this.machine.EcoLibrary; }
    set ecoLibrary(ecoLibrary: EcoLibrary) { this.machine.EcoLibrary = ecoLibrary; }
    get ecoLibraryId(): string { return this.machine['ParentId']; }
    set ecoLibraryId(ecoLibraryId: string) { this.machine['ParentId'] = ecoLibraryId; }
    get ecoAddressId(): string { return this.machine['IdRefAddress']; }
    set ecoAddressId(addressId: string) { this.machine['IdRefAddress'] = addressId; }
    get image(): string {
        if (this.machine.Image) {
            return 'data:image/png;base64,' + this.machine.Image[0].Object;
        }
    }
    get imageId(): string {
        if (this.machine.Image) {
            return this.machine.Image[0].Id;
        }
    }

    constructor(machine: Machine) {
        this.machine = machine;
    }

    prepareMachineReq(): MachinePersistModel {
        const req: MachinePersistModel = {
            Name: this.trialMachineName,
            ConstructionYear: this.constructionYear,
            //dropbox grid is used for control, ManufacturerCompany and type, its value is in format [...selectedGridKeys] 
            Type: this.machineType ? this.machineType : null,
            Control: this.control ? this.control : null,
            ManufacturerCompany: this.manufacturer ? this.manufacturer : null,
            Description: this.description,
            ParentId: this.ecoLibraryId,
            IdRefAddress: this.ecoAddressId
        }
        return req;
    }
}

