<form action="post" (submit)="persistEmployee($event)">
    <dx-form #form id="form" [(formData)]="employee">
        <dxi-item class="floatingLabel">
            <dx-text-box labelMode="floating" mode="number"
                [label]="dataService.res('Eco-Employees-Personal-Number')+'*'" [(value)]="employee.personnelNumber">
            </dx-text-box>
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item class="floatingLabel">
            <dx-select-box class="drop-down-style" [(value)]="employee.title" displayExpr='Description'
                valueExpr='Value' [dataSource]="salutations" [label]="dataService.res('Eco-Employees-Titel-Anrede')+'*'"
                [labelMode]="'floating'">
            </dx-select-box>
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item class="floatingLabel">
            <dx-text-box name="vorname" labelMode="floating" [(value)]="employee.firstName"
                [label]="dataService.res('Eco-Employees-Vorname')+'*'">
            </dx-text-box>
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item class="floatingLabel">
            <dx-text-box name="nachname" labelMode="floating" [(value)]="employee.lastName"
                [label]="dataService.res('Eco-Employees-Nachname')+'*'">
            </dx-text-box>
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item class="floatingLabel">
            <dx-text-box name="kurzel" labelMode="floating" [label]="dataService.res('Eco-Employees-Kurzel')+'*'"
                [(value)]="employee.login">
            </dx-text-box>
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item class="floatingLabel">
            <eco-drop-box [(gridValue)]="employee.function" [gridDataSource]="functionStore"
                [label]="'Eco-Employees-Funktion'" (selectionChange)="changeFunctionName($event)"></eco-drop-box>
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item class="floatingLabel">
            <dx-text-box name="mail" labelMode="floating" [(value)]="employee.eMail" mode="email"
                [label]="dataService.res('Eco-Employees-E-Mail-Adresse')+'*'">
            </dx-text-box>
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item [cssClass]="returnDxItemCssClass('title-bloc-container')" itemType="group"
            style="margin-bottom: 0px; width: 100%; padding-right: 0px;">
            <dxi-item>
                <p class="title-bloc">{{ dataService.res('Eco-Customers-Phone') }}</p>
            </dxi-item>
        </dxi-item>
        <dxi-item [cssClass]="returnDxItemCssClass('phone-number')" itemType="group" [colCount]="15"
            style="width: 100%;">

            <ww-phone [formGroup]="phoneForm" [(isReady)]="isPhoneReady" [(value)]="employee.phoneNumber.Number"
                [dialCode]="employee.phoneNumber.CountryCode" [(countryISO)]="employee.phoneNumber.Designation"
                [key]="'employeePhone'"></ww-phone>
        </dxi-item>
        <dxi-item itemType="group">
            <div *dxTemplate="let data of 'dxi-item'">
                <app-eco-uploader #pictureUploader [acceptedFileType]="'image/*'" dropZoneWidth="100%"
                    [title]="'Eco-Employees-Profile-Picture'" [object]="object" [fileDesignation]="'profilePicture'"
                    [imgSource]="employee.profilePicture">
                </app-eco-uploader>
            </div>
        </dxi-item>
        <dxi-item [cssClass]="returnDxItemCssClass('div-buttons')" itemType="group">
            <dxi-item [cssClass]="returnDxItemCssClass('btn-create')" itemType="button"
                [(buttonOptions)]="createButtonOptions">
            </dxi-item>
            <dxi-item [cssClass]="returnDxItemCssClass('btn-cancel')" itemType="button"
                [buttonOptions]="CancelButtonOptions">
            </dxi-item>
        </dxi-item>
    </dx-form>
</form>