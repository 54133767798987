
import { Address, PhoneNumber } from "@geods/base";
import { PersonViewModel } from "./person.view-model";

interface CustomerPersistModel {
    Name: string;
    Name2: string;
    CustomerNumber: string;
    MatchCode: string;
    Status: string;
    Phone2: string;
    Street: string;
    City: string;
    Country: string;
    ZIP: string;
}
export class CustomerViewModel {
    protected customer: Address;
    private _persons: PersonViewModel[] = [];
    get id(): string { return this.customer.Id; }
    get customerName(): string { return this.customer.Name; }
    set customerName(customerName: string) { this.customer.Name = customerName; }
    get customerNumber(): string { return this.customer.CustomerNumber; }
    set customerNumber(customerNumber: string) { this.customer.CustomerNumber = customerNumber; }
    get customerMatchCode(): string { return this.customer.MatchCode; }
    set customerMatchCode(customerMatchCode: string) { this.customer.MatchCode = customerMatchCode; }
    get customerPhone(): string {
        if (this.customer.PhoneNumbers && this.customer.PhoneNumbers[0].CountryCode && this.customer.PhoneNumbers[0].Number) {
            return `+${this.customer.PhoneNumbers[0].CountryCode} ${this.customer.PhoneNumbers[0].Number}`;
        }
    }
    get customerStatus(): string { return this.customer.Status; }
    set customerStatus(customerStatus: string) { this.customer.Status = customerStatus; }
    get creationDate(): Date { return this.customer.SysDateInsert; }
    set creationDate(creationDate: Date) { this.customer.SysDateInsert = creationDate; }
    get street(): string { return this.customer.Street; }
    set street(street: string) { this.customer.Street = street; }
    get city(): string { return this.customer.City; }
    set city(city: string) { this.customer.City = city; }
    get zip(): string { return this.customer.ZIP; }
    set zip(ZIP: string) { this.customer.ZIP = ZIP; }
    get country(): string { return this.customer.Country; }
    set country(country: string) { this.customer.Country = country; }
    get address(): string {
        let address = [this.customer.Street, this.customer.ZIP, this.customer.City, this.customer.Country]
            .filter((value) => value != null && value != undefined).join(',');
        return address;
    }
    set address(address: string) { this.customer.City = address; }
    get customerEmail(): string { return this.customer.Name2; }
    set customerEmail(customerEmail: string) { this.customer.Name2 = customerEmail; }
    get contactPersons(): PersonViewModel[] {
        this._persons = [];
        this.customer?.Persons?.forEach(person => {
            {
                this._persons?.push(new PersonViewModel(person))
            }
        })
        return this._persons;
    }
    set contactPersons(persons: PersonViewModel[]) {
        this._persons = persons;
    }
    get phoneNumber(): PhoneNumber {
        if (this.customer.PhoneNumbers && this.customer.PhoneNumbers[0]) { return this.customer.PhoneNumbers[0]; }
        else {
            return new PhoneNumber(null)
        }
    }
    set phoneNumber(phoneNumber: PhoneNumber) {
        if (this.customer.PhoneNumbers && this.customer.PhoneNumbers[0])
            this.customer.PhoneNumbers[0] = phoneNumber;
    }

    constructor(customer: Address) {
        this.customer = customer;
    }

    public formatContactNumber(value: string) {
        if (value && !value.includes(")")) {
            return "+" + value.substring(0, 2) + "(" + value.substring(2, 6) + ")" + value.substring(6, 11) + "-" + value.substring(11, 13);
        } else {
            return value;
        }
    }

    private formatPhoneNumber(phone): string {
        if (phone && phone.CountryCode) {
            return '+' + [phone.CountryCode,
            phone.LocalAreaCode,
            phone.Number,
            phone.Mediation].filter((number) => (
                number != null &&
                number != undefined &&
                number.length > 0
            )).join(' ')
        }
    }
    prepareCustomerReq(): CustomerPersistModel {
        let objectBody = { ...this.customer };
        delete objectBody['Persons'];
        delete objectBody['PhoneNumbers'];
        return objectBody;
    }
}
