
  <div [ngClass]="{'full-content-app':isAuthenticated(), 'full-content-login' : !isAuthenticated() }"  >
  <header fxLayout="row" >
    <img  [ngClass]="{'header-app':isAuthenticated(), 'header-login' : !isAuthenticated() }" src="assets/images/header.png" />
  </header>
<ng-container *ngIf="isAuthenticated(); else unauthenticated">
  <dx-drawer fxFill fxLayout="column" class="drawer" position="before" [closeOnOutsideClick]="shaderEnabled"
    [openedStateMode]="menuMode" [revealMode]="menuRevealMode" [minSize]="minMenuSize" [shading]="shaderEnabled"
    [(opened)]="menuOpened">
    <!-- SideNav -->
    <div fxLayout="column" id="menu" *dxTemplate="let dataMenu of 'panel'">
      <dx-toolbar fxLayout="row" id="navigation-header">
        <dxi-item location="before" widget="dxButton" widget="dxButton">
          <button mat-button class="menu-button" (click)="toggleMenu($event)">
            <mat-icon style="color: #fff;font-size: 32px;">{{ menuOpened ? 'menu_open' : 'menu' }}</mat-icon>
          </button>
        </dxi-item>
        <dxi-item location="center" cssClass="header-title" html={{ecoBaseLogo}}></dxi-item>
      </dx-toolbar>
      <div fxLayout="row" class="menu-container">
        <dx-tooltip [target]="target" [(visible)]="isVisible">
          <div *dxTemplate="let data = data of 'content'">
            <span>{{tooltipContent}}</span>
          </div>
        </dx-tooltip>
        <dx-tree-view #treeview [items]="navigation" keyExpr="path" selectionMode="single" [focusStateEnabled]="false"
          expandEvent="click" (onContentReady)="onContentReady($event)" (onItemClick)="onMenuItemClick($event)"
          width="100%">
        </dx-tree-view>
      </div>
    </div>
    <!-- End SideNav -->
    <div fxFlex="100" fxLayout="column" class="container" id="container">
      <header fxLayout="row" id="header">
        <dx-toolbar class="header-toolbar" fxLayout="column" fxLayoutAlign="center center">
          <dxi-item style="width: inherit; font-weight: 700;" [cssClass]="headerMainTitleCSS()" location="before"
            [text]="'ECOROLL'">
          </dxi-item>
          <dxi-item location="after">
            <div class="user-info">
              <img class="userLogo" src="assets/images/User.svg" />
              <div class="user-name">
                {{ currentUser?.displayName }}
              </div>
            </div>
          </dxi-item>
          <dxi-item location="after" locateInMenu="auto" menuItemTemplate="menuItem">
            <img class="contextMenuHeader" src="assets/images/ContextMenu.svg" />
          </dxi-item>
        </dx-toolbar>
        <dx-context-menu class="settings-context-menu" target=".contextMenuHeader" showEvent="dxclick"
          [items]="contextMenuItems" [position]="{ my: 'top right', at: 'bottom right' }">
        </dx-context-menu>
      </header>
      <dx-scroll-view>
        <router-outlet fxFill fxLayout="row" (activate)="onRouterOutletActivate()"></router-outlet>
      </dx-scroll-view>
    </div>
  </dx-drawer>
</ng-container>
<ww-toast #toast [animation]="toastAnimation"></ww-toast>
<ng-template #unauthenticated>
  <core-authentication-login #login appLogo="{{ecoBaseLogo}}" [stylingMode]="'underlined'"
    [enableForgotPassword]="false" [hasInfoLinks]="false">
  </core-authentication-login>
</ng-template>
</div>