import { GeoDsDocument, GeoDsMeta } from "@geods/base";

export class Roughness extends GeoDsMeta {
    ArithmeticRoughnessDepth: number;
    AspectRatio: number;
    AveragePeakToValleyHeight: number;
    CoreGrooveDepth: number;
    CutoffWavelength: number;
    ImprovementAveragedRoughness: number;
    ImprovementArithmeAvedRoughness: number;
    LargestMaterialShare: number;
    MaterialProfileProportion: number;
    MaximumRoughnessDepth: number;
    MediumGrooveSpacing: number;
    ModeledRoughness: number;
    ReducedCenterHeight: number;
    CenterHeight: number;
    ReducedDepth: number;
    RootMeanSquareRoughness: number;
    RoughnessSkewness: number;
    RoughnessSlope: number;
    SmallestMaterialShare: number;
    TotalProfileHeight: number;
    ValleyDepth: number;
    ImprovementRoughnessChange: number;
    ProfilePicture: GeoDsDocument;
    ThreeDSurface: GeoDsDocument;
    SurfacePhoto: GeoDsDocument;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}