import { GeoDsDocument, GeoDsMeta } from "@geods/base";

export class SelfVoltage extends GeoDsMeta {
    SurfaceResidualStressesPar: number;
    MaxResidualCompressiveStressesPar: number;
    SurfaceResidualStressesTraverse: number;
    DepthMaxResidualCompressiveStressesPar: number;
    DepthPenetrationResidualStressesPar: number;
    DepthMaxResidualCompressStressTraverse: number;
    DepthPenetrationResidualStressTraverse: number;
    ResidualStressDepthCurveParX1: number;
    ResidualStressDepthCurveParY1: number;
    ResidualStressDepthProfileTranverseX1: number;
    ResidualStressDepthProfileTranverseY1: number;
    HalfWidthDepthCurveX1: number;
    HalfWidthDepthCurveY1: number;
    FWHMDepthGradientX: number;
    FWHMDepthGradientY: number;
    HardnessPenetrationDepth: number;
    MaxResidualCompressiveStressesTransverse: number;
    ProtocolMeasureDoc: GeoDsDocument;
    LipidDoc: GeoDsDocument;


    constructor(data) {
        super();
        Object.assign(this, data);
    }
}