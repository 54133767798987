import { Component, OnInit, ViewChild } from '@angular/core';
import { Technology } from '@ecoroll/models';
import { ToastType } from '@wissenswerft/ww-library';
import { DxPopupComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { Subscription } from 'rxjs';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { TechnologyViewModel } from '../view-models/technology.view-model';
import { CreateTechnologyComponent } from './create-technology/create-technology.component';
import { saveAs } from 'file-saver-es';
@Component({
  selector: 'ecoBase-technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.scss']
})
export class TechnologyComponent implements OnInit {
  @ViewChild('createForm') createForm: CreateTechnologyComponent;
  @ViewChild('addTechnologyPopup') addTechnologyPopup: DxPopupComponent;
  @ViewChild("deleteTechnologyPopup") deleteTechnologyPopup: DxPopupComponent;
  public showLoader = true;
  public selectedTechnologyId: string;
  private subscriptions: Subscription[] = [];
  public technologies: TechnologyViewModel[] = [];

  constructor(public dataService: DataService,
    private appService: AppService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.dataService.readNestedObjectsWithColumns<Technology>(
        {
          modelName: ObjectKeys.TECHNOLOGY,
          ObjectQueries: [
            {
              modelName: ObjectKeys.Document, Opath: "Documents[Designation='Image']", Name: "Image",
              columns: ['Id', 'Designation'],
              specialColumns: [{ name: "Object", opath: "Object", format: 'Base64Image[100x100]' }]
            }
          ]
        }
      ).subscribe((technologies) => {
        if (technologies.length > 0) {
          technologies?.forEach((technology, index) => {
            this.technologies.push(new TechnologyViewModel(technology));
            if (index + 1 === technologies.length) {
              this.showLoader = false;
            }
          })
        } else {
          this.showLoader = false;
        }
      }, (error) => {
        this.showLoader = false;
        this.appService.callNotification({
          message: this.dataService.res('Eco-Error'),
          type: ToastType.ERROR
        });
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public openTechnologyDialog(data?): void {
    if (data?.data) {
      const technologyModel = { ... { ...data?.data }["technology"] };
      this.createForm.technology = new TechnologyViewModel(technologyModel);
      this.createForm.update = true;
    } else {
      const technology = new TechnologyViewModel(new Technology({}));
      this.createForm.technology = technology;
      this.createForm.update = false;
      this.createForm.form.instance.resetValues();
    }
    this.addTechnologyPopup.instance.show();
  }

  public closePopup(): void {
    this.addTechnologyPopup.instance.hide();
  }

  public createTechnology(event: { isUpdated: boolean, Id: string }): void {
    this.showLoader = true;
    this.dataService.readNestedObjectsWithColumns<Technology>(
      {
        modelName: ObjectKeys.TECHNOLOGY,
        condition: "Id='" + event.Id + "'",
        ObjectQueries: [
          {
            modelName: ObjectKeys.Document, Opath: "Documents[Designation='Image']", Name: "Image",
            specialColumns: [{ name: "Object", opath: "Object" }]
          }
        ]
      }
    ).subscribe((persistedTechnologyData) => {
      const persistedTechnology = new TechnologyViewModel(persistedTechnologyData[0]);
      if (event.isUpdated) {
        let selectedIndex = this.technologies.findIndex((technology) => technology.id === event.Id);
        this.technologies[selectedIndex] = persistedTechnology;
        this.showLoader = false;
      } else {
        this.technologies.unshift(persistedTechnology);
        this.showLoader = false;
      }
    })
  }

  public openDeleteTechnologyDialog(event): void {
    this.selectedTechnologyId = event.data.id;
    this.deleteTechnologyPopup.instance.show();
  }
  public deleteTechnology(): void {
    this.subscriptions.push(
      this.dataService
        .deleteObject(ObjectKeys.TECHNOLOGY, this.selectedTechnologyId)
        .subscribe(
          (deletedTechnology) => {
            if (deletedTechnology?.Id) {
              this.technologies = this.technologies.filter(
                (technology) => technology.id != deletedTechnology?.Id
              );
              this.appService.callNotification({
                message: this.dataService.res('Eco-Success'),
                type: ToastType.SUCCESS
              });
            }
          },
          (error) => {
            this.appService.callNotification({
              message: this.dataService.res('Eco-Error'),
              type: ToastType.ERROR
            });
          }, () => {
            this.onCloseDeletePopup();
          }
        )
    );
  }

  public onCloseDeletePopup(): void {
    this.deleteTechnologyPopup.instance.hide();
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: (options) => {
        const { excelCell, gridCell } = options;
        if (gridCell.column.dataField === "description" && gridCell.rowType !== "header") {
          excelCell.alignment = { wrapText: true };
          const temporalDivElement = document.createElement("div");
          temporalDivElement.innerHTML = excelCell.value;
          const parentNode = temporalDivElement;
          const reachTextArray = [];
          const rgbToHex = (rgb) => {
            let hex = Number(rgb).toString(16);
            if (hex.length < 2) {
              hex = "0" + hex;
            }
            return hex;
          };
          const fullColorHex = (r, g, b) => {
            const red = rgbToHex(r);
            const green = rgbToHex(g);
            const blue = rgbToHex(b);
            return red + green + blue;
          };
          const styleByNodeName = {
            "STRONG": "bold",
            "EM": "italic",
            "U": "underline"
          }
          const _getParentNodeNames = (node) => {
            const parentNodeNames = [];
            const getParentNodeName = (node) => {
              if (node.parentNode !== null) {
                parentNodeNames.push(node.nodeName);
                getParentNodeName(node.parentNode);
              }
            };
            getParentNodeName(node);
            return parentNodeNames;
          }
          const _getStylesByParenNodeNames = (node) => {
            const font = {};
            _getParentNodeNames(node).forEach((nodeName) => {
              if (nodeName === "STRONG" || nodeName === "EM" || nodeName === "U") {
                font[styleByNodeName[nodeName]] = true;
              }
              if (node.parentNode && node.parentNode.attributes && node.parentNode.attributes.style) {
                const colorAttr = node.parentNode.attributes.style.value;
                const matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
                const match = matchColors.exec(colorAttr);
                if (match !== null) {
                  font["color"] = { argb: fullColorHex(match[1], match[2], match[3]) };
                }
              }
            });
            return font;
          }
          const inOrder = (nodes, fontStyle?) => {
            nodes.forEach((node) => {
              if (node.nodeName === "#text") {
                let config;
                if (node.previousSibling === null && (node.parentNode.nodeName === "P" || node.parentNode.nodeName === "DIV")) {
                  config = { text: " \n" + node.nodeValue };
                } else {
                  config = { text: node.nodeValue };
                }
                config.font = _getStylesByParenNodeNames(node);
                reachTextArray.push(config);
              } else {
                inOrder(node.childNodes);
              }
            });
          };
          if (parentNode) {
            inOrder(parentNode.childNodes);
            excelCell.value = { 'richText': reachTextArray };
          }
        }
      }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });
  }

}
