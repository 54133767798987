import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { MachineViewModel } from '../../view-models/machine.view-model';
import {
  GeoDsPersistenceService,
  ObjectKey,
  PersistMode,
  PersistObjectModel,
  TargetColumnValue,
  TargetObjectData
} from '@wissenswerft/core/data';
import { Machine } from '@ecoroll/models';
import { Observable, Subscription } from 'rxjs';
import { AppService } from '../../../services/app.service';
import { ToastType } from '@wissenswerft/ww-library';
import { PERSISTDATA, UploadService } from '../../../services/upload.service';
import { EcoUploaderComponent } from '../../shared/eco-uploader/eco-uploader.component';
import { Column } from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
@Component({
  selector: 'ecoBase-create-machine',
  templateUrl: './create-machine.component.html',
  styleUrls: ['./create-machine.component.scss']
})
export class CreateMachineComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: DxFormComponent;
  @ViewChild('pictureUploader') pictureUploader: EcoUploaderComponent;
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  @Output() createMachineOutput: EventEmitter<{ isUpdated: boolean, Id: string }> = new EventEmitter();
  public machine: MachineViewModel = new MachineViewModel(new Machine({}));
  public object = ObjectKeys.ECOMACHINE;
  public newControl = '';
  public parentId = '';
  public update = false;
  public displayConfirmAddControlOptionPopUp = false;
  public isGridBoxOpened = false;
  public controlGridDataSource: CustomStore;
  public typesGridDataSource: CustomStore;
  public manufacturersGridDataSource: CustomStore;
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Eco-Description'),
      dataField: 'Description',
      dataType: 'string',
      visibleIndex: 0
    }
  ];
  public createButtonOptions = {
    text: this.dataService.res('Eco-Create'),
    useSubmitBehavior: true
  };

  public CancelButtonOptions = {
    text: this.dataService.res('Eco-Cancel'),
    onClick: () => this.onClosePopup()
  };

  constructor(
    public dataService: DataService,
    private persistenceService: GeoDsPersistenceService,
    private appService: AppService,
    private uploadService: UploadService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.dataService.loadListDataSource(ObjectKeys.ECOLIBRARY, "Id").subscribe((libraries) => {
      this.parentId = libraries.Data[0]?.Id;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public createMachine(event): void {
    event.preventDefault();
    this.persistMachine().subscribe(data => {
      const employeePicturePersistData: PERSISTDATA = {
        parentId: data.Id,
        object: ObjectKeys.ECOMACHINE,
        designation: "imgMachine",
        docId: this.machine.image ? this.machine.imageId : undefined

      }
      this.uploadService.onLaunchPersist(employeePicturePersistData);
      this.createMachineOutput.emit({ isUpdated: this.update, Id: data.Id });
    }, (error) => {
      this.appService.callNotification({
        message: this.dataService.res('Eco-Error'),
        type: ToastType.ERROR
      });
    }, () => {
      this.appService.callNotification({
        message: this.dataService.res('Eco-Success'),
        type: ToastType.SUCCESS
      });
      this.onClosePopup();
    })

  }

  public onClosePopup(): void {
    this.closePopup.emit(true);
    if (!this.update) {
      this.pictureUploader.reset();
    }

  }

  public persistMachine(): Observable<any> {
    const machineQuery = this.machine.prepareMachineReq();
    machineQuery.ParentId = this.parentId;

    const machinePersistQuery: TargetObjectData = new TargetObjectData();
    machinePersistQuery.ObjectKey = new ObjectKey();
    if (this.update) {
      machinePersistQuery.Mode = PersistMode.Update;
      machinePersistQuery.ObjectKey.Id = this.machine.id;
    } else {
      machinePersistQuery.Mode = PersistMode.Insert;
    }

    machinePersistQuery.ObjectKey.ObjectType = ObjectKeys.ECOMACHINE;
    const machineColumns: TargetColumnValue[] = this.dataService.geoDsPersistBody(machineQuery);

    machinePersistQuery.TargetColumns = machineColumns;
    const query: PersistObjectModel = new PersistObjectModel();
    query.Object = machinePersistQuery;
    return this.persistenceService.executePersistObjectQuery(query);
  }

  public showConfirmAddControlOptionPopup(displayConfirmAddControlOptionPopUp): void {
    this.displayConfirmAddControlOptionPopUp = displayConfirmAddControlOptionPopUp;
  }

  public onCustomItemCreating(event): void {
    if (event.text.trim() != '') {
      this.newControl = event.text;
      this.showConfirmAddControlOptionPopup(true);
    }
  }

  public onGridBoxOptionChanged(e) {
    if (e.name === 'value') {
      this.isGridBoxOpened = false;
      this.cdr.detectChanges();
    }
  }

}
