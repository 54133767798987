<div fxLayout="column" class="content-style-class" [style.filter]="showLoader == true ? 'blur(0.2rem)' : 'blur(0)'">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="title-style">{{dataService.res('Eco-Employees-title')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-speed-dial-action icon="add" (onClick)="openEmployeeDialog()">
                </dx-speed-dial-action>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <dx-data-grid #employeeGrid class="grid-group-by-style customeExportStyle employee-grid"
                [hoverStateEnabled]="true" [id]="'employeeGrid'" [dataSource]="employees" [height]="'100%'"
                [showBorders]="true" [showRowLines]="true" [showColumnLines]="true">
                <dxo-selection mode="single"></dxo-selection>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
                <dxo-header-filter [visible]="false"></dxo-header-filter>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                <dxi-column [caption]="dataService.res('Eco-Image')" width="15%" cellTemplate="images"
                    [allowExporting]="false">
                </dxi-column>

                <dxi-column [caption]="dataService.res('Eco-Employees-Vorname')" style="font-weight: bold;"
                    dataField="firstName"></dxi-column>
                <dxi-column [caption]="dataService.res('Eco-Employees-Nachname')" style="font-weight: bold;"
                    dataField="lastName">
                </dxi-column>

                <dxi-column [caption]="dataService.res('Eco-Employees-Kurzel')" dataField="login">
                </dxi-column>
                <dxi-column [caption]="'Personalnummer'" dataField="personnelNumber">
                </dxi-column>
                <dxi-column [caption]="dataService.res('Eco-Employees-Creation-Date')" dataField="creationDate">
                </dxi-column>
                <dxi-column [caption]="dataService.res('Eco-Employees-Funktion')" dataField="function">
                    <dxo-lookup [dataSource]="functions" valueExpr="Value" displayExpr="Description">
                    </dxo-lookup>
                </dxi-column>
                <dxi-column [caption]="dataService.res('Eco-Employees-E-Mail-Adresse')" dataField="eMail">
                </dxi-column>
                <dxi-column [caption]="dataService.res('Eco-Employees-Private-Phone')" dataField="employeePhone">
                </dxi-column>

                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

                <dxi-column [width]="'80px'" cellTemplate="buttons"></dxi-column>

                <div *dxTemplate="let employeeImage of 'images'">
                    <div class="image-bloc">
                        <img class="employee-img" *ngIf="employeeImage.data.profilePicture"
                            [src]="employeeImage.data.profilePicture" />
                        <img class="employee-img" *ngIf="!employeeImage.data.profilePicture"
                            src="assets/images/no-image.png" />
                    </div>
                </div>
                <div *dxTemplate="let employee of 'buttons'">

                    <div class="td-action">
                        <img (click)="openEmployeeDialog(employee.data)" class="grid-action"
                            src="assets/images/edit.svg">
                        <img (click)="openDeletePersonDialog(employee.data)" class="grid-action"
                            src="assets/images/delete.svg">
                    </div>
                </div>
            </dx-data-grid>
            <dx-popup #addEmployeePopup class="popup-window-style"
                title="{{dataService.res('Eco-Employees-Create-Employee')}}" [width]="'50%'" [height]="'90%'"
                [showCloseButton]="'true'">
                <dx-scroll-view>
                    <ecoBase-create-employee #createForm (closePopup)="closePopup()"
                        (createEmploye)="createEmploye($event)">
                    </ecoBase-create-employee>
                </dx-scroll-view>
            </dx-popup>

            <dx-popup #deletePersonPopup [width]="'25%'" [showTitle]="false" fxLayout="column"
                fxLayoutAlign="center center" [height]="'auto'" [showCloseButton]="true">
                <div class="delete-popup">
                    <div *ngIf="isDeleteWarning" class="warning-msg"><i
                            class="dx-icon-warning dx-icon-custom-style"></i>
                        {{dataService.res(warningMsg)}}</div>
                    <div *ngIf="!isDeleteWarning">{{ dataService.res('Eco-Employees-Delete-Msg') }}</div>
                    <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
                        <dx-button *ngIf="!isDeleteWarning" class="btn-create" [text]="dataService.res('Eco-Delete')"
                            (onClick)="deletePerson()">
                        </dx-button>
                        <dx-button class="btn-cancel" [text]="dataService.res('Eco-Cancel')"
                            (onClick)="onCloseDeletePopup()">
                        </dx-button>
                    </dxi-item>
                </div>
            </dx-popup>
        </div>
    </div>
</div>
<ecoBase-eco-loader *ngIf="showLoader"></ecoBase-eco-loader>