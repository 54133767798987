import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MeasuringDevice } from '@ecoroll/models';
import { GeoDsCoreDataService } from '@wissenswerft/core/data';
import { EditingMode, GridComponent, ToastType } from '@wissenswerft/ww-library';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-measuring-devices',
  templateUrl: './measuring-devices.component.html',
  styleUrls: ['./measuring-devices.component.scss']
})
export class MeasuringDevicesComponent implements OnInit, OnDestroy {
  @ViewChild('measuringDevicesGrid') measuringDevicesGrid: GridComponent;
  @ViewChild("deleteMeasuringDevicePopup") deleteMeasuringDevicePopup: DxPopupComponent;
  public showLoader = true;
  public editingMode = EditingMode.Row;
  public measuringDevices: MeasuringDevice[] = [];
  public measuringDeviceIdToDelete: string;
  private subscriptions: Subscription[] = [];
  public isDeleteWarningVisible = false;
  public warningMsg = '';
  public showUpdateRow = false;
  public showCreateRow = false;
  public columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Eco-Measure-Devices-Designation'),
      dataField: 'Designation',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      type: 'buttons',
      caption: '',
      alignment: 'left',
      showInColumnChooser: false,
      fixed: true,
      minWidth: 70,
      buttons: [
        {
          icon: 'edit',
          text: 'Edit',
          visible: (e) => !this.isUpdateVisible() && !this.isInsertVisible(),
          onClick: (event) => { this.startUpdate(event) }
        },
        {
          icon: 'save',
          text: 'Save',
          visible: (e) => this.isUpdateVisible() || this.isInsertVisible(),
          onClick: (event) => {
            if (this.isUpdateVisible()) {
              this.updateMeasure(event)
            } else if (this.isInsertVisible()) {
              this.insertMeasure(event)
            }
          }
        },
        {
          icon: 'trash',
          text: this.dataService.res('Eco-Delete'),
          visible: (e) => !this.isUpdateVisible() && !this.isInsertVisible(),
          onClick: (event) => {
            this.openDeleteMeasuringDevicePopup(event)
          }
        },
        {
          icon: 'undo',
          text: this.dataService.res('Eco-Cancel'),
          visible: (e) => this.isUpdateVisible() || this.isInsertVisible(),
          onClick: (event) => {
            this.cancelPersist(event)
          }
        }
      ]
    }
  ];

  constructor(
    public dataService: DataService,
    private coreDataService: GeoDsCoreDataService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    let columns = [
      this.coreDataService.createQueryColumn('Id', 'Id'),
      this.coreDataService.createQueryColumn('Designation', 'Designation')
    ];
    this.dataService.readObjects(ObjectKeys.MEASURINGDEVICE, columns).subscribe((measuringDevices) => {
      this.measuringDevices = Array.isArray(measuringDevices) && measuringDevices.length > 0 ? measuringDevices : [];
    }, (error) => {
      this.appService.callNotification({
        message: this.dataService.res('Eco-Error'),
        type: ToastType.ERROR
      });
    }, () => {
      this.showLoader = false;
    })
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe() });
  }
  public addMeasureDeviceDialog(): void {
    this.showCreateRow = true;
    this.measuringDevicesGrid.addRow();
  }
  public openDeleteMeasuringDevicePopup(event): void {
    this.measuringDeviceIdToDelete = event.row.data.Id;
    this.dataService.readNestedObjectsWithColumns({
      modelName: ObjectKeys.MEASURINGDEVICEREPORT,
      columns: ['Id', 'IdRefMeasuringDevice'],
      condition: `IdRefMeasuringDevice='${this.measuringDeviceIdToDelete}'`
    }).subscribe((reportMeasuringDevices) => {
      if (reportMeasuringDevices && reportMeasuringDevices.length > 0) {
        this.isDeleteWarningVisible = true;
        this.warningMsg = this.dataService.res('Eco-Measure-Devices-Delete-Msg-Error');
        this.deleteMeasuringDevicePopup.instance.show();
      } else {
        this.isDeleteWarningVisible = false;
        this.warningMsg = this.dataService.res('Eco-Measure-Devices-Delete-Msg');
        this.deleteMeasuringDevicePopup.instance.show();
      }
    }, (error) => {

    })

  }
  private isUpdateVisible(): boolean {
    return this.showUpdateRow;
  }
  private isInsertVisible(): boolean {
    return this.showCreateRow;
  }
  private startUpdate(event): void {
    this.showUpdateRow = true;
    this.measuringDevicesGrid.dxDataGrid.instance.editRow(event.row.rowIndex);
  }
  private updateMeasure(event): void {

    let measureWithSameDesignation = this.measuringDevices.filter((measure) => measure.Designation === event.row.data.Designation);

    if (measureWithSameDesignation && measureWithSameDesignation.length > 0) {

      this.appService.callNotification({
        message: this.dataService.res('Eco-Measure-Devices-Same-Designation-Error'),
        type: ToastType.ERROR
      });
      this.showUpdateRow = false;
      this.measuringDevicesGrid.dxDataGrid.instance.cancelEditData();
    } else {

      const measureDevice = (({ Id, Designation }) => ({ Id, Designation }))(event.row.data);
      this.subscriptions.push(
        this.dataService.persistMultipleData({
          update: [{ modelName: ObjectKeys.MEASURINGDEVICE, data: [measureDevice] }]
        })
          //this.riskAssessmentService.updateRiskAssessmentMeasureRiskReduction(Id, false, RiskReduction)
          .subscribe((updatedMeasuringDevices) => {
            this.showUpdateRow = false;
            this.measuringDevicesGrid.dxDataGrid.instance.saveEditData();
            this.appService.callNotification({
              message: this.dataService.res('Eco-Success'),
              type: ToastType.SUCCESS
            });
          }, (error) => {
            console.error(error);
            this.showUpdateRow = false;
            this.measuringDevicesGrid.dxDataGrid.instance.cancelEditData();
            this.appService.callNotification({
              message: this.dataService.res('Eco-Error'),
              type: ToastType.ERROR
            });
          }));
    }
  }
  private insertMeasure(event): void {
    let measureWithSameDesignation = this.measuringDevices.find((measure) => measure.Designation === event.row.data.Designation);

    if (measureWithSameDesignation) {

      this.appService.callNotification({
        message: this.dataService.res('Eco-Measure-Devices-Same-Designation-Error'),
        type: ToastType.ERROR
      });
      this.showCreateRow = false;
      this.measuringDevicesGrid.dxDataGrid.instance.cancelEditData();
    } else {
      this.measuringDevicesGrid.dxDataGrid.instance.saveEditData();
    }
  }
  public onRowInserted(event) {
    const measureDevice = (({ Designation }) => ({ Designation }))(event.data);
    this.subscriptions.push(
      this.dataService.persistMultipleData({
        insert: [{ modelName: ObjectKeys.MEASURINGDEVICE, data: [measureDevice] }]
      }).pipe(map(measure => (
        measure[0][0]
      )))
        .subscribe((insertedMeasuringDevices) => {
          for (let measure of this.measuringDevices) {
            if (measure.Designation == event.data.Designation) {
              measure.Id = insertedMeasuringDevices.Id;
              break;
            }
          }
          this.showCreateRow = false;
          this.measuringDevicesGrid.dxDataGrid.instance.saveEditData();
          this.appService.callNotification({
            message: this.dataService.res('Eco-Success'),
            type: ToastType.SUCCESS
          });
        }, (error) => {
          console.error(error);
          this.showUpdateRow = false;
          this.measuringDevicesGrid.dxDataGrid.instance.cancelEditData();
          this.appService.callNotification({
            message: this.dataService.res('Eco-Error'),
            type: ToastType.ERROR
          });
        }));
  }
  public onCloseDeletePopup(): void {
    this.deleteMeasuringDevicePopup.instance.hide();
  }
  public deleteMeasureDevice(): void {
    this.dataService.deleteObject(ObjectKeys.MEASURINGDEVICE, this.measuringDeviceIdToDelete)
      .subscribe(
        (deletedMeasureDevice) => {
          if (deletedMeasureDevice?.Id) {
            this.measuringDevices = this.measuringDevices.filter(
              (measureDevice) => measureDevice.Id != deletedMeasureDevice?.Id
            );
            this.appService.callNotification({
              message: this.dataService.res('Eco-Success'),
              type: ToastType.SUCCESS
            });
          }
        },
        (error) => {
          this.appService.callNotification({
            message: this.dataService.res('Eco-Error'),
            type: ToastType.ERROR
          });
          this.deleteMeasuringDevicePopup.instance.hide();
        },
        () => {
          this.deleteMeasuringDevicePopup.instance.hide();
        }
      )
  }
  public cancelPersist(event): void {
    this.showUpdateRow = false;
    this.showCreateRow = false;
    this.measuringDevicesGrid.dxDataGrid.instance.cancelEditData();
  }

}
