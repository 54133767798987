import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Address, PhoneNumber } from '@geods/base';
import { GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, PersistObjectsModel, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../../../services/app.service';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { CustomerViewModel } from '../../view-models/customer.view-model';
import { PersonViewModel } from '../../view-models/person.view-model';
import { CustomersService } from '../customers.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export enum GridPersistMode {
  INSERT = 'insert',
  UPDATE = 'update',
  REMOVE = 'remove'
}
@Component({
  selector: 'ecoBase-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {
  @ViewChild('customerform') form: DxFormComponent;
  @ViewChild('employeesGrid') employeesGrid: GridComponent;
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
  @Output() createCustomerOutput: EventEmitter<{ isUpdated: boolean, Id: string }> = new EventEmitter();
  public customer: CustomerViewModel = new CustomerViewModel(new Address(null));
  public employeesIdsToBeDeleted: Array<string> = [];
  public employeesIdsToBeAdded: Array<PersonViewModel> = [];
  public employeesIdsToBeUpdated: Array<PersonViewModel> = [];
  public update = false;
  private subscriptions: Subscription[] = [];
  public phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  });
  public isPhoneReady = false;
  public createButtonOptions = {
    text: this.dataService.res('Eco-Create'),
    useSubmitBehavior: true
  };
  public CancelButtonOptions = {
    text: this.dataService.res('Eco-Cancel'),
    onClick: () => this.onClosePopup()
  };
  public assignedEmployeesColumns: Column[] = [
    {
      caption: this.dataService.res('Eco-Employees-Vorname'),
      dataField: 'lastName',
      dataType: 'string',
      visibleIndex: 0
    },
    {
      caption: this.dataService.res('Eco-Employees-Nachname'),
      dataField: 'firstName',
      dataType: 'string',
      visibleIndex: 1
    },
    {
      caption: this.dataService.res('Eco-Employees-E-Mail-Adresse'),
      dataField: 'eMail',
      dataType: 'string',
      validationRules: [{
        ignoreEmptyValue: false,
        message: this.dataService.res('Eco-Customers-Email-Required'),
        type: 'email'
      }],
      visibleIndex: 3
    },
    {
      caption: this.dataService.res('Eco-Employees-Private-Phone'),
      dataField: 'personnelNumber',
      dataType: 'number',
      visibleIndex: 4,
      validationRules: [{
        type: 'stringLength',
        ignoreEmptyValue: false,
        message: this.dataService.res('Eco-Customers-Phone-Required'),
        max: 9
      }],
    },
  ];

  constructor(
    public dataService: DataService,
    private persistenceService: GeoDsPersistenceService,
    private appService: AppService,
    public customersService: CustomersService
  ) { }

  ngOnInit(): void { }

  public createCustomer(event): void {
    event.preventDefault();

    const addCustomerWithEmployees$ = this.persistCustomer().pipe(
      switchMap((persistedCustomer) => {
        return forkJoin([
          this.persistPhoneNumber(this.customer.phoneNumber, persistedCustomer.Id),
          this.persistEmployeesList(persistedCustomer.Id)
        ]).pipe(map(([phone, employees]) => ({
          persistedCustomer: persistedCustomer,
          phone: phone,
          employees: employees
        })));
      }
      ));

    this.subscriptions.push(
      addCustomerWithEmployees$.subscribe(({ persistedCustomer, phone, employees }) => {
        this.employeesIdsToBeUpdated = [];
        this.employeesIdsToBeAdded = [];
        this.employeesIdsToBeDeleted = [];
        this.createCustomerOutput.emit({ isUpdated: this.update, Id: persistedCustomer.Id });
      }, (error) => {
        this.appService.callNotification({
          message: this.dataService.res('Eco-Error'),
          type: ToastType.ERROR
        });
      }, () => {
        this.appService.callNotification({
          message: this.dataService.res('Eco-Success'),
          type: ToastType.SUCCESS
        });
        this.closePopup.emit(true);
      })
    );
  }

  public persistCustomer(): Observable<any> {

    const customerPersistQuery: TargetObjectData = new TargetObjectData();
    customerPersistQuery.ObjectKey = new ObjectKey();
    if (this.update) {
      customerPersistQuery.Mode = PersistMode.Update;
      customerPersistQuery.ObjectKey.Id = this.customer.id;
    } else {
      customerPersistQuery.Mode = PersistMode.Insert;
    }
    customerPersistQuery.ObjectKey.ObjectType = ObjectKeys.ADDRESS;
    //mandatory field in backend but not requested by the client
    this.customer.customerStatus = 'KD';
    const customerColumns: TargetColumnValue[] = this.dataService.geoDsPersistBody(this.customer.prepareCustomerReq());
    customerPersistQuery.TargetColumns = customerColumns;
    const query: PersistObjectModel = new PersistObjectModel();
    query.Object = customerPersistQuery;
    return this.persistenceService.executePersistObjectQuery(query);
  }

  public persistPhoneNumber(phone: PhoneNumber, companyId: string): Observable<any> {

    const [countryCode, number] = phone.Number ? phone.Number.split(' ') : [null, null];

    if (number) {

      const phoneColumns: TargetColumnValue[] = [
        { Name: 'CountryCode', Value: countryCode },
        { Name: 'Number', Value: number },
        { Name: 'Designation', Value: phone.Designation },
      ];
      const phonePersistQuery: TargetObjectData = new TargetObjectData();
      phonePersistQuery.ObjectKey = new ObjectKey();
      phonePersistQuery.ObjectKey.ObjectType = ObjectKeys.PHONENUMBER;
      phonePersistQuery.TargetColumns = phoneColumns;
      const persistObject: PersistObjectModel = new PersistObjectModel();
      persistObject.Object = phonePersistQuery;
      if (phone.Id) {
        phonePersistQuery.Mode = PersistMode.Update;
        phonePersistQuery.ObjectKey.Id = phone.Id;
      } else {
        phonePersistQuery.Mode = PersistMode.Insert;
        phoneColumns.push(
          { Name: 'ParentId', Value: companyId },
          { Name: 'Type', Value: phone.Type },
          { Name: 'Show', Value: true }
        );
      }
      return this.persistenceService.executePersistObjectQuery(persistObject)
    } else {
      return of([])
    }
  }

  public persistEmployeesList(companyId: string): Observable<any> {
    const employessToAdd = this.employeesIdsToBeAdded.map((employee) => {
      return {
        FirstName: employee.firstName,
        LastName: employee.lastName,
        EMail: employee.eMail,
        PersonnelNumber: employee.personnelNumber,
        ParentId: companyId
      }
    });

    const employessToUpdate = this.employeesIdsToBeUpdated.map((employee) => {
      return {
        Id: employee.id,
        FirstName: employee.firstName,
        LastName: employee.lastName,
        EMail: employee.eMail,
        PersonnelNumber: employee.personnelNumber,
      }
    });
    return this.dataService.persistMultipleData({
      insert: [{ modelName: ObjectKeys.PERSON, data: employessToAdd }],
      update: [{ modelName: ObjectKeys.PERSON, data: employessToUpdate }],
      delete: [{ modelName: ObjectKeys.PERSON, data: this.employeesIdsToBeDeleted }]
    });
  }

  public addRow(): void {
    this.employeesGrid.addRow();
  }
  public saveRow(event): void {
    if (event.changes[0].type === GridPersistMode.INSERT) {
      this.employeesIdsToBeAdded.push(event.changes[0].data);
    }

    if (event.changes[0].type === GridPersistMode.UPDATE) {
      if (event.changes[0].data.id) {
        this.employeesIdsToBeUpdated.push(event.changes[0].data);
      } else {
        this.employeesIdsToBeAdded.map((employee) => {
          if (employee["__KEY__"] === event.changes[0].data.__KEY__) {
            employee = event.changes[0].data;
          }
        })
      }
    }

    if (event.changes[0].type === GridPersistMode.REMOVE) {
      const id = event?.changes[0].key.id;
      if (id) {
        this.employeesIdsToBeDeleted.push(id)
        this.employeesIdsToBeUpdated = this.employeesIdsToBeUpdated.filter((employee) => {
          return employee.id != id;
        })
      } else {
        this.employeesIdsToBeAdded = this.employeesIdsToBeAdded.filter((employee) => {
          return employee["__KEY__"] != event?.changes[0].key["__KEY__"];
        })
      }
    }
    this.employeesGrid.refreshGrid();
  }

  public onClosePopup(): void {
    this.employeesIdsToBeUpdated = [];
    this.employeesIdsToBeAdded = [];
    this.employeesIdsToBeDeleted = [];
    if (this.employeesGrid.dxDataGrid.instance.hasEditData()) {
      this.employeesGrid.dxDataGrid.instance.cancelEditData();
    }
    this.closePopup.emit(true);
  }

  public returnDxItemCssClass(className: string): string {
    return className;
  }

}
