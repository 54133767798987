<div fxLayout="column" class="content-style-class" [style.filter]="showLoader === true ? 'blur(0.2rem)' : 'blur(0)'">
    <div class="content-block">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <h2 class="title-style">{{dataService.res('Eco-Machines-Title')}}</h2>
            </div>
            <div fxLayout="column" class="add-button-style">
                <dx-speed-dial-action icon="add" (onClick)="openMachineDialog()">
                </dx-speed-dial-action>
            </div>
        </div>
        <div class="dx-card responsive-paddings">
            <dx-data-grid class="grid-group-by-style" [id]="'machines'" [dataSource]=" machines" [height]="'100%'"
                dataRowTemplate="dataRowTemplateName" [showBorders]="true" [showRowLines]="false"
                [showColumnLines]="false" [hoverStateEnabled]="true">
                <dxo-selection mode="single"></dxo-selection>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"
                    [title]="dataService.res('Eco-Search')">
                </dxo-search-panel>
                <dxi-column [caption]="dataService.res('Eco-Machines-Image')" [width]="'20%'"></dxi-column>
                <dxi-column [caption]="dataService.res('Eco-Machines-Name')" dataField="trialMachineName"
                    [width]="'20%'">
                </dxi-column>
                <dxi-column [caption]="dataService.res('Eco-Machines-Control')" dataField="control" [width]="'20%'">
                    <dxo-lookup [dataSource]="controls" valueExpr="Value" displayExpr="Description">
                    </dxo-lookup>
                </dxi-column>
                <dxi-column [caption]="dataService.res('Eco-Materials-Machine-Type')" dataField="machineTypeName"
                    [width]="'20%'">
                    <dxo-lookup [dataSource]="types" valueExpr="Description" displayExpr="Description">
                    </dxo-lookup>
                </dxi-column>
                <dxi-column [caption]="dataService.res('Eco-Materials-Manufacturer')" dataField="manufacturerName"
                    [width]="'20%'">
                    <dxo-lookup [dataSource]="manufacturers" valueExpr="Description" displayExpr="Description">
                    </dxo-lookup>
                </dxi-column>
                <dxi-column [caption]="dataService.res('Eco-Machines-Baujahr')" dataField="year" alignment="left"
                    [width]="'20%'">
                </dxi-column>
                <dxi-column type="buttons">
                </dxi-column>
                <ng-container *dxTemplate="let machine of 'dataRowTemplateName'">
                    <tr class="tr-machine">
                        <td rowspan="2" class="td-border" style="border-bottom: 1px solid #ddd;">
                            <div class="image-bloc">
                                <img class="machine-img" *ngIf="machine.data.image" [src]="machine.data.image" />
                                <img class="machine-img" *ngIf="!machine.data.image" src="assets/images/no-image.png" />
                            </div>
                        </td>
                        <td class="grid-text-style machine-name">{{machine.data.trialMachineName }}</td>
                        <td class="grid-text-style">{{machine.data.controlName}}</td>
                        <td class="grid-text-style">{{machine.data.machineTypeName }}</td>
                        <td class="grid-text-style">{{machine.data.manufacturerName }}</td>
                        <td class="grid-text-style">{{machine.data.constructionYear }}</td>
                        <td rowspan="2" style="border-bottom: 1px solid #ddd;">
                            <div class="td-action">
                                <img (click)="openMachineDialog(machine)" class="grid-action"
                                    src="assets/images/edit.svg">
                                <img (click)="openDeleteMachineDialog(machine)" class="grid-action"
                                    src="assets/images/delete.svg">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5"
                            style="border-bottom: 1px solid #ddd; white-space: break-spaces; overflow-wrap: break-word;">
                            <span class="grid-machine-designation"
                                *ngIf="machine.data.machineDesignation">{{dataService.res('Eco-Description')}}:</span>
                            {{machine.data.machineDesignation }}
                        </td>
                    </tr>
                </ng-container>
            </dx-data-grid>
        </div>
    </div>
</div>
<dx-popup #addMachinePopup class="popup-window-style" title="{{dataService.res('Eco-Machines-Creation-Title')}}"
    [width]="'50%'" [height]="'auto'" [showCloseButton]="true" [showCloseButton]="'true'">
    <ecoBase-create-machine #createForm (closePopup)="closePopup()" (createMachineOutput)="createMachineOutput($event)">
    </ecoBase-create-machine>
</dx-popup>
<dx-popup #deleteMachinePopup [width]="'25%'" [showTitle]="false" fxLayout="column" fxLayoutAlign="center center"
    [height]="'auto'" [showCloseButton]="true">
    <div class="delete-popup">
        <div>{{dataService.res('Eco-Machines-Delete-Msg')}}</div>
        <dxi-item itemType="group" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
            <dx-button class="btn-create" [text]="dataService.res('Eco-Delete')" (onClick)="deleteMachine()">
            </dx-button>
            <dx-button class="btn-cancel" [text]="dataService.res('Eco-Cancel')" (onClick)="onCloseDeletePopup()">
            </dx-button>
        </dxi-item>
    </div>
</dx-popup>

<ecoBase-eco-loader *ngIf="showLoader"></ecoBase-eco-loader>