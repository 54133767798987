<div fxLayout="column" class="content-style-class" [style.filter]="showLoader == true ? 'blur(0.2rem)' : 'blur(0)'">
    <div class="content-block">
        <div fxLayout="column">
            <h2 class="title-style">{{dataService.res('Eco-Pick-List')}}</h2>
        </div>
        <div class="dx-card responsive-paddings card-radius" style="text-align: center">
            <div fxLayout="row" fxFill fxLayoutGap="10px">


                <dx-data-grid [id]="'pickLists'" class="grid-style" [height]="'100%'" [dataSource]="pickLists"
                    [showBorders]="true" [showRowLines]="true" [showColumnLines]="true"
                    (onRowClick)="loadDataBank($event)">
                    <dxo-group-panel [visible]="false"></dxo-group-panel>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-scrolling mode="virtual"></dxo-scrolling>
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <dxi-column [caption]="dataService.res('Eco-Pick-List-Designation')" dataField="Designation">
                    </dxi-column>

                    <dxi-column [caption]="dataService.res('Eco-Pick-List-Language')" dataField="Language">
                    </dxi-column>
                    <dxo-paging [enabled]="false"></dxo-paging>

                </dx-data-grid>


                <dx-data-grid #pickListItems [id]="'pickListItems'" class="grid-style" [height]="'100%'"
                    [dataSource]="dataBank" [showBorders]="true" [showRowLines]="true" [showColumnLines]="true"
                    [noDataText]="dataService.res('Eco-Pick-List-No-Data-Text')" [columns]="columnsHeader"
                    (onSaved)="persistPickList($event)">
                    <dxo-group-panel [visible]="false"></dxo-group-panel>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-scrolling mode="virtual"></dxo-scrolling>
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <dxi-column [caption]="dataService.res('Eco-Pick-List-Description')" dataField="Description">
                    </dxi-column>
                    <dxo-paging [enabled]="false"></dxo-paging>
                    <!-- <dxi-column [caption]="dataService.res('Eco-Pick-List-Value')" dataField="Value">
                    </dxi-column> -->


                    <dxo-editing [mode]="'row'" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false"
                        [useIcons]="true"> </dxo-editing>
                </dx-data-grid>



            </div>
        </div>

    </div>
</div>
<dx-speed-dial-action class="add-button-style" icon="add" [index]="1" (onClick)="addRow()" [visible]="enableAddColumn">
</dx-speed-dial-action>



<ecoBase-eco-loader *ngIf="showLoader"></ecoBase-eco-loader>