import { Address, GeoDsDocument, GeoDsMeta, Person } from "@geods/base";
import { EcoTest } from "./eco-test.model";
import { TestReportPerson } from "./test-report-person.model";
import { TestReportMachine } from "./test-report-machine.model";
import { TestReportMaterial } from "./test-report-material.model";
import { TestReportTool } from "./test-report-tool.model";
import { TargetValue } from "./target-value.model";
import { Technology } from "./technology.model";
import { TestReportMeasuringDevice } from "./test-report-measuring-device.model";
export class TestReport extends GeoDsMeta {
    ParentId: string;
    Customer: Address;
    TestPartName: string;
    Date: Date;
    Description: string;
    Objective: string;
    ReportNr: string;
    TargetValues: TargetValue[];
    TechnologyRef: Technology;
    ExperimentalEmployee: Person;
    ProjectManager: Person;
    IdRefPerson: string;
    IdRefProjectManager: string;
    IdRefAddressCustomer: string;
    SpecialFeatures: string;
    ProcessType: string;
    PreliminaryProcess: boolean;
    Offer: string;
    ResultDescription: string;
    Tests: EcoTest[];
    Tools: TestReportTool[];
    Materials: TestReportMaterial[];
    Machines: TestReportMachine[];
    MeasuringDevices: TestReportMeasuringDevice[];
    PreProcessFromCustomer: boolean;
    ImgReport3dModel: GeoDsDocument[];
    ImgWorkpieceDrawing: GeoDsDocument;
    ReportFile: GeoDsDocument[];
    MultiplesImages: GeoDsDocument[];
    TestReportContacts: TestReportPerson[];
    GeometryName: string;
    ApplicationTechnician: Person | [Person];

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}