import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PersistMode, QueryColumn, TargetColumnValue } from '@wissenswerft/core/data';
import { AppService } from '../../services/app.service';
import { DataService, ObjectKeys } from '../../services/data.service';
import { Subscription } from 'rxjs';
import { Column } from 'devextreme/ui/data_grid';
import { first } from 'rxjs/operators';
import { GridComponent, ToastType } from '@wissenswerft/ww-library';


@Component({
  selector: 'ecoBase-pick-list',
  templateUrl: './pick-list.component.html',
  styleUrls: ['./pick-list.component.scss'],

})
export class PickListComponent implements OnInit {
  @ViewChild('pickListItems') pickListItems: GridComponent;

  public showLoader = false;
  public pickLists = [];
  public enableAddColumn = false;
  public dataBank = [];
  public readonly pickListColumns: QueryColumn[] = [
    {
      OPath: 'Id',
      Name: 'Id',
    },
    {
      OPath: 'Designation',
      Name: 'Designation',
    },
    {
      OPath: 'Name',
      Name: 'Name',
    },
    {
      OPath: 'Language',
      Name: 'Language',
    }
  ];
  public readonly pickListItemColumns: QueryColumn[] = [
    {
      OPath: 'Id',
      Name: 'Id',
    },
    {
      OPath: 'Description',
      Name: 'Description',
    },
    {
      OPath: 'Value',
      Name: 'Value',
    },
  ];
  public columnsHeader: Column[] = [
    {
      caption: 'Bezeichnung',
      dataField: 'Description',
      dataType: 'string',
      visibleIndex: 0,
    },
    {
      caption: 'Wert',
      dataField: 'Value',
      dataType: 'string',
      width: '20%',
      visibleIndex: 1,
    },
  ];
  public pickListColumnsHeader: Column[] = [
    {
      caption: 'Description',
      dataField: 'Description',
      dataType: 'string',
      visibleIndex: 0,
    },
    {
      caption: 'Language',
      dataField: 'Language',
      dataType: 'string',
      visibleIndex: 0,
    }
  ];
  public readonly TAG_COLUMNS: QueryColumn[] = [
    { Name: 'Id', OPath: 'Id' },
    { Name: 'Designation', OPath: 'Designation' },
  ];
  private subscriptions: Subscription[] = [];

  private selectedPickListId: string;

  constructor(
    public dataService: DataService,
    private cdr: ChangeDetectorRef,
    private appService: AppService,
  ) { }



  ngOnInit(): void {
    const pickListOpath = "StartsWith(Name,'ec') Or Name ='Personenfunktion' Or Name ='Anrede'";

    this.dataService
      .readObjects(ObjectKeys.PICKLIST, this.pickListColumns, pickListOpath)
      .pipe(first())
      .subscribe(
        (_pickListData) => {
          if (Array.isArray(_pickListData) && _pickListData.length > 0) {
            this.pickLists = _pickListData.sort((a, b) => a.Designation.localeCompare(b.Designation));
            this.pickLists.forEach(picklist => {
              picklist.Language == '1031' ? picklist.Language = this.dataService.res("Language-de") : picklist.Language = this.dataService.res("Language-en");
            });
          }
        },
        (error) => {
          console.error(error);
          this.showLoader = false;
          this.appService.callNotification({
            message: this.dataService.res('Eco-Error'),
            type: ToastType.ERROR
          });
        },
        () => {
          this.showLoader = false;
          this.cdr.markForCheck();
        }
      );
  }
  public loadDataBank = (event): void => {
    this.showLoader = true;
    this.enableAddColumn = true;
    this.selectedPickListId = event.data.Id;
    const pickListItemOpath = 'ParentId=' + "'" + this.selectedPickListId + "'";
    this.dataService
      .readObjects(ObjectKeys.PICKLISTITEM, this.pickListItemColumns, pickListItemOpath)
      .pipe(first())
      .subscribe(
        (_pickListItem) => {
          if (Array.isArray(_pickListItem) && _pickListItem.length > 0) {
            this.dataBank = _pickListItem.sort((a, b) => a.Description.localeCompare(b.Description));
          }
        },
        (error) => {
          console.error(error);
          this.showLoader = false;
          this.appService.callNotification({
            message: this.dataService.res('Eco-Error'),
            type: ToastType.ERROR
          });
        },
        () => {
          this.showLoader = false;
          this.cdr.markForCheck();
        }
      );
  }

  public addRow(): void {
    this.pickListItems['instance'].addRow();
  }

  public persistPickList = (event): void => {
    if (event.changes[0].type == ChangeMode.Remove) {
      const pickListId = event.changes[0].key.Id;
      this.subscriptions.push(
        this.dataService.deleteObject(ObjectKeys.PICKLISTITEM, pickListId).subscribe(
          () => { },
          (error) => {
            console.error(error);
            this.appService.callNotification({
              message: this.dataService.res('Eco-Error'),
              type: ToastType.ERROR
            });
          },
          () => {
            this.appService.callNotification({
              message: this.dataService.res('Eco-Success'),
              type: ToastType.SUCCESS
            });
          }
        )
      );
    } else if (event.changes[0].type == ChangeMode.Update) {
      const pickListId = event.changes[0].data?.Id;
      //const pickListValue = event.changes[0].data.Value;
      const pickListDescription = event.changes[0].data.Description;
      const pickListColumns: TargetColumnValue[] = [
        //{ Name: 'Value', Value: pickListValue },
        { Name: 'Description', Value: pickListDescription },
        { Name: 'ParentId', Value: this.selectedPickListId },
      ];

      this.subscriptions.push(
        this.dataService.persistObject(PersistMode.Update, ObjectKeys.PICKLISTITEM, pickListColumns, pickListId).subscribe(
          (_pickList) => {
            if (event.changes[0].type == ChangeMode.Update && !this.dataBank[this.dataBank.length - 1]?.Id) {
              this.dataBank[this.dataBank.length - 1].Id = _pickList[0].Id;
            }
          },
          (error) => {
            console.error(error);
            this.appService.callNotification({
              message: this.dataService.res('Eco-Error'),
              type: ToastType.ERROR
            });
          },
          () => {
            this.appService.callNotification({
              message: this.dataService.res('Eco-Success'),
              type: ToastType.SUCCESS
            });
          }
        )
      );
    }
    else if (event.changes[0].type === ChangeMode.Insert) {
      const pickListId = event.changes[0].data?.Id;
      //const pickListValue = event.changes[0].data.Value;
      const pickListDescription = event.changes[0].data.Description;
      const pickListColumns: TargetColumnValue[] = [
        { Name: 'Value', Value: pickListDescription },
        { Name: 'Description', Value: pickListDescription },
        { Name: 'ParentId', Value: this.selectedPickListId },
      ];

      let sameValue = this.dataBank.filter((value) => value.Value == pickListDescription);

      if (sameValue.length > 1) {
        this.dataBank.splice(-1, 1)
        this.appService.callNotification({
          message: this.dataService.res('Eco-Pick-List-Same-Value-Error'),
          type: ToastType.ERROR
        });
      } else {
        this.subscriptions.push(
          this.dataService.persistObject(PersistMode.Insert, ObjectKeys.PICKLISTITEM, pickListColumns, pickListId).subscribe(
            (_pickList) => {
              if (event.changes[0].type === ChangeMode.Insert && !this.dataBank[this.dataBank.length - 1].Id) {
                this.dataBank[this.dataBank.length - 1].Id = _pickList['Id'];
              }
            },
            (error) => {
              console.error(error);
              this.appService.callNotification({
                message: this.dataService.res('Eco-Error'),
                type: ToastType.ERROR
              });
            },
            () => {
              this.appService.callNotification({
                message: this.dataService.res('Eco-Success'),
                type: ToastType.SUCCESS
              });
            }
          )
        );
      }
    }
  };


  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => {
      subscription.unsubscribe();
    });
  }
}

export enum ChangeMode {
  Insert = "insert",
  Remove = "remove",
  Update = "update"
}


