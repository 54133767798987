import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Column } from 'devextreme/ui/data_grid';
import { DataService } from '../../../services/data.service';



@Component({
  selector: 'eco-drop-box',
  templateUrl: './eco-drop-box.component.html',
  styleUrls: ['./eco-drop-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EcoDropBoxComponent implements OnInit {
  @Input() gridValue: any;
  @Output() gridValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() gridDataSource: CustomStore;
  @Input() label: string;
  @Input() isValid: boolean = true;
  @Output() isValidChange = new EventEmitter<boolean>();
  @Input() columnsHeader: Column[] = [
    {
      caption: this.dataService.res('Eco-Description'),
      dataField: 'Description',
      dataType: 'string',
      visibleIndex: 0
    }
  ];
  public isGridBoxOpened = false;
  constructor(
    public dataService: DataService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  public onGridBoxOptionChanged(event) {
    if (event.name === 'value') {
      this.isGridBoxOpened = false;
      this.cdr.detectChanges();
    }
  }

  public onSelectionChanged(event): void {
    this.gridValueChange.emit(event.selectedRowKeys);
    this.selectionChange.emit(event.selectedRowKeys[0]);
  }

}
