import { Technology } from "@ecoroll/models";
export class TechnologyViewModel {
    protected technology: Technology;
    get id(): string { return this.technology.Id; }
    set id(id: string) { this.technology.Id = id; }
    get name(): string { return this.technology.Name; }
    set name(name: string) { this.technology.Name = name; }
    get description(): string { return this.technology.Description; }
    set description(description: string) { this.technology.Description = description; }
    get image(): string {
        if (this.technology.Image) {
            return 'data:image/png;base64,' + this.technology.Image[0].Object;
        }
    }
    get imageId(): string {
        if (this.technology.Image) {
            return this.technology.Image[0].Id;
        }
    }

    constructor(technology: Technology) {
        this.technology = technology;
    }

    prepareTechnologyReq(): Technology {
        let objectBody = { ...this.technology };
        delete objectBody['Image'];
        return objectBody;

    }
}

