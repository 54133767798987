import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { DataService, ObjectKeys } from '../../../services/data.service';
import { GeoDsPersistenceService, ObjectKey, PersistMode, PersistObjectModel, QueryColumn, TargetColumnValue, TargetObjectData } from '@wissenswerft/core/data';
import { AppService } from '../../../services/app.service';
import { ToastType } from '@wissenswerft/ww-library';
import { Subscription } from 'rxjs';
import { PERSISTDATA, UploadService } from '../../../services/upload.service';
import { EcoUploaderComponent } from '../../shared/eco-uploader/eco-uploader.component';
import { TechnologyViewModel } from '../../view-models/technology.view-model';
import { Technology } from '@ecoroll/models';

@Component({
  selector: 'ecoBase-create-technology',
  templateUrl: './create-technology.component.html',
  styleUrls: ['./create-technology.component.scss']
})
export class CreateTechnologyComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: DxFormComponent;
  @ViewChild('imageUploader') imageUploader: EcoUploaderComponent;
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  @Output() createTechnology: EventEmitter<{ isUpdated: boolean, Id: string }> = new EventEmitter();

  public technology: TechnologyViewModel = new TechnologyViewModel(new Technology(null));
  public object = ObjectKeys.TECHNOLOGY;
  public update = false;
  private subscriptions: Subscription[] = [];
  public createButtonOptions = {
    text: this.dataService.res('Eco-Create'),
    useSubmitBehavior: true
  };

  public CancelButtonOptions = {
    text: this.dataService.res('Eco-Cancel'),
    onClick: () => this.onClosePopup()
  };
  constructor(
    public dataService: DataService,
    private appService: AppService,
    private persistenceService: GeoDsPersistenceService,
    private uploadService: UploadService
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public persistTechnology(event): void {
    event.preventDefault();
    this.subscriptions.push(this.persistTechnologyReq()
      .subscribe((data) => {
        const technologyImagePersistData: PERSISTDATA = {
          parentId: data.Id,
          object: ObjectKeys.TECHNOLOGY,
          designation: "image",
          docId: this.technology.image ? this.technology.imageId : undefined

        }
        this.uploadService.onLaunchPersist(technologyImagePersistData);

        this.createTechnology.emit({ isUpdated: this.update, Id: data.Id })
      }, (error) => {
        this.appService.callNotification({
          message: this.dataService.res('Eco-Error'),
          type: ToastType.ERROR
        });
      }, () => {
        this.appService.callNotification({
          message: this.dataService.res('Eco-Success'),
          type: ToastType.SUCCESS
        });
        this.onClosePopup();
      })
    )
  }
  
  public persistTechnologyReq() {
    const technolgyQuery = this.technology.prepareTechnologyReq();
    const technologyPersistQuery: TargetObjectData = new TargetObjectData();
    technologyPersistQuery.ObjectKey = new ObjectKey();
    if (this.update) {
      technologyPersistQuery.Mode = PersistMode.Update;
      technologyPersistQuery.ObjectKey.Id = this.technology.id;
    } else {
      technologyPersistQuery.Mode = PersistMode.Insert;
    }
    technologyPersistQuery.ObjectKey.ObjectType = ObjectKeys.TECHNOLOGY;
    const technolgyColumns: TargetColumnValue[] = this.dataService.geoDsPersistBody(technolgyQuery);
    technologyPersistQuery.TargetColumns = technolgyColumns;
    const query: PersistObjectModel = new PersistObjectModel();
    query.Object = technologyPersistQuery;
    return this.persistenceService.executePersistObjectQuery(query);
  }

  public onClosePopup(): void {
    this.closePopup.emit(true);
    if (!this.update) {
      this.imageUploader.reset();
    }
  }

  public emptyData(): void {
    this.form.instance.resetValues();
    this.technology = new TechnologyViewModel(new Technology(null));
  }

  public returnDxItemCssClass(className: string): string {
    return className;
  }


}
